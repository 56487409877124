import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client';
import { onError } from 'apollo-link-error';

export const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, path }): void => {
        // if ((!path || path[0] !== 'me') && extensions && extensions.code === 'FORBIDDEN' && window.location.pathname !== '/login') {
        //   window.location.href = '/login';
        // }
      });
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL || 'http://localhost:5000/',
    credentials: 'include'
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink as unknown as ApolloLink, httpLink]),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      }
    }
  });
};
