import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children, props }: GuardProps) => {
  const { isLoggedIn, isInitialized, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isInitialized && !isLoggedIn) {
      navigate('login', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
    if (props?.permission && !user?.permissions?.includes(props.permission)) {
      navigate('/maintenance/403', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
  }, [isInitialized, isLoggedIn, navigate, location, user, props]);

  return children;
};

export default AuthGuard;
