// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { AppProvider as TourProvider } from './layout/MainLayout/context';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from 'utils/apollo-client';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <TourProvider>
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <ApolloProvider client={createApolloClient()}>
            <AuthProvider>
              <>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <Notistack>
                    <Routes />
                    <Snackbar />
                  </Notistack>
                </QueryParamProvider>
              </>
            </AuthProvider>
          </ApolloProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  </TourProvider>
);

export default App;
