// material-ui
// import { useTheme } from '@mui/material/styles';
// import { ThemeMode } from 'types/config';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <svg width="43" height="18" viewBox="0 0 43 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.575 1.4H11.525V3.525H3.3V8.4H10.6V10.5H3.3V17.5H0.575V1.4ZM16.0292 17.75C15.0292 17.75 14.2875 17.5083 13.8042 17.025C13.3208 16.5417 13.0792 15.7583 13.0792 14.675V0.649999H15.8042V14.425C15.8042 14.875 15.8792 15.1917 16.0292 15.375C16.1958 15.5417 16.4542 15.625 16.8042 15.625C17.2042 15.625 17.5875 15.575 17.9542 15.475V17.45C17.4042 17.65 16.7625 17.75 16.0292 17.75ZM25.2357 17.75C23.1357 17.75 21.5274 17.2333 20.4107 16.2C19.294 15.1667 18.7357 13.6167 18.7357 11.55C18.7357 9.6 19.2274 8.09167 20.2107 7.025C21.194 5.94167 22.6524 5.4 24.5857 5.4C26.3357 5.4 27.6774 5.85833 28.6107 6.775C29.544 7.69167 30.0107 8.91667 30.0107 10.45V12.65H21.3357C21.469 13.7833 21.8774 14.575 22.5607 15.025C23.244 15.4583 24.319 15.675 25.7857 15.675C26.3857 15.675 26.994 15.6167 27.6107 15.5C28.244 15.3833 28.8024 15.2333 29.2857 15.05V17.05C28.2357 17.5167 26.8857 17.75 25.2357 17.75ZM27.6107 10.875V10.05C27.6107 9.2 27.3774 8.55833 26.9107 8.125C26.444 7.675 25.694 7.45 24.6607 7.45C23.4274 7.45 22.5607 7.71667 22.0607 8.25C21.5774 8.78333 21.3357 9.65833 21.3357 10.875H27.6107ZM35.4719 11.475L31.1219 5.65H34.0469L36.9469 9.6L39.8469 5.65H42.7719L38.4469 11.475L42.9469 17.5H39.9969L36.9469 13.375L33.8969 17.5H30.9469L35.4719 11.475Z"
          fill="#2451F1"
        />
      </svg>
    </>
  );
};

export default LogoMain;
