"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Errors = void 0;
var Errors;
(function (Errors) {
    Errors["INVALID_CREDENTIALS"] = "invalid_credentials";
    Errors["COMPANIES_GROUP_FROZEN"] = "companies_group_frozen";
    Errors["USER_ALREADY_EXIST_BY_PHONE"] = "user_already_exist_by_phone";
    Errors["USER_ALREADY_EXIST_BY_EMAIL"] = "user_already_exist_by_email";
    Errors["USER_REGISTRATION_SMS_ALREADY_SENT"] = "user_registration_sms_already_sent";
    Errors["BIND_EXISTING_CLIENT"] = "bind_existing_client";
})(Errors = exports.Errors || (exports.Errors = {}));
