"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permissions = void 0;
var Permissions;
(function (Permissions) {
    Permissions["MANAGE_COMPANIES_GROUPS"] = "manage_companies_groups";
    Permissions["MANAGE_COMPANIES_ROLES"] = "manage_companies_roles";
    Permissions["MANAGE_COMPANIES"] = "manage_companies";
    Permissions["MANAGE_COMPANY_GROUPS"] = "manage_company_groups";
    Permissions["MANAGE_COMPANY_EMPLOYEE"] = "manage_company_employee";
    Permissions["MANAGE_CLIENTS"] = "manage_clients";
    Permissions["MEET_CLIENTS"] = "meet_clients";
    Permissions["SALESMAN"] = "salesman";
    Permissions["APPRAISER"] = "appraiser";
    Permissions["ACCESSORIES_SPECIALIST"] = "accessories_specialist";
    Permissions["LOAN_OFFICER"] = "loan_officer";
    Permissions["PRE_SALE_SPECIALIST"] = "pre_sale_specialist";
    Permissions["SELF_SERVICE_INFO"] = "self_service_info";
})(Permissions = exports.Permissions || (exports.Permissions = {}));
