import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import { AuthProps, JWTContextType } from 'types/auth';
import {
  useLoginEmailOrPhoneMutation,
  useLogoutMutation,
  useGetCurrentUserLazyQuery,
  useRegisterMutation,
  useRequestResetPasswordMutation,
  AuthContextEnum
} from '../utils/graphql';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// const verifyToken: (st: string) => boolean = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded: KeyedObject = jwtDecode(serviceToken);
//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };

// const setSession = (serviceToken?: string | null) => {
//   if (serviceToken) {
//     localStorage.setItem('serviceToken', serviceToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
//   } else {
//     localStorage.removeItem('serviceToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const [loginByEmail] = useLoginEmailOrPhoneMutation();
  const [registerStart] = useRegisterMutation();
  const [logoutMutation] = useLogoutMutation();
  const [resetPasswordMutation] = useRequestResetPasswordMutation();
  const [currentUser] = useGetCurrentUserLazyQuery();

  useEffect(() => {
    const init = async () => {
      try {
        const result = await currentUser();
        if (result.data?.getCurrentUser) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: result.data.getCurrentUser
            }
          });
        } else {
          dispatch({ type: LOGOUT });
        }
      } catch (error) {
        dispatch({ type: LOGOUT });
      }
    };

    init();
  }, [currentUser]);

  const login = async (email: string, password: string) => {
    const result = await loginByEmail({ variables: { emailOrPhone: email, password } });
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: result.data?.loginEmailOrPhone
      }
    });
  };

  const register: JWTContextType['register'] = async ({ email, name, phone }) => {
    await registerStart({ variables: { input: { email, name, phone } } });
  };

  const logout = () => {
    logoutMutation()
      .then(() => dispatch({ type: LOGOUT }))
      .catch((err) => {
        console.error('Cannot logout', err);
      });
  };

  const resetPassword = async (email: string) => {
    await resetPasswordMutation({ variables: { email, authContext: AuthContextEnum.AdminPanel } });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
