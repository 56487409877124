import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Outlet } from 'react-router';
import { Permissions } from 'shared';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceError403 = Loadable(lazy(() => import('pages/maintenance/403')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const ListRoles = Loadable(lazy(() => import('pages/roles/list')));
const ListCompanies = Loadable(lazy(() => import('pages/companies/list')));
const ListUsersGroups = Loadable(lazy(() => import('pages/users-groups/list')));
const ListCars = Loadable(lazy(() => import('pages/cars/list')));
const ListDirections = Loadable(lazy(() => import('pages/directions/list')));
const ListEmployee = Loadable(lazy(() => import('pages/employee/list')));
const ListClients = Loadable(lazy(() => import('pages/clients/list')));
const ListArchivedClients = Loadable(lazy(() => import('pages/clients/archive')));
const FullItemClients = Loadable(lazy(() => import('pages/clients/full-item')));
const SelfServiceInfo = Loadable(lazy(() => import('pages/self-service-info')));
const CallbackInfo = Loadable(lazy(() => import('pages/callbacks')));
const CompaniesGroups = Loadable(lazy(() => import('pages/companies-groups/list')));

const UserProfile = Loadable(lazy(() => import('pages/profile')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/dashboard',
          element: <SamplePage />,
          default: true
        },
        {
          path: 'roles',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANIES_ROLES }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListRoles />
            }
          ]
        },
        {
          path: 'companies',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANIES }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListCompanies />
            }
          ]
        },
        {
          path: 'users-groups',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANY_GROUPS }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListUsersGroups />
            }
          ]
        },
        {
          path: 'cars',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANIES }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListCars />
            }
          ]
        },
        {
          path: 'directions',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANY_GROUPS }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListDirections />
            }
          ]
        },
        {
          path: 'employee',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANY_EMPLOYEE }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListEmployee />
            }
          ]
        },
        {
          path: 'clients',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_CLIENTS }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: 'list',
              element: <ListClients />
            },
            {
              path: 'archive',
              element: <ListArchivedClients />
            },
            {
              path: ':id',
              element: <FullItemClients />
            }
          ]
        },
        {
          path: 'self-service-info',
          element: <SelfServiceInfo />
        },
        {
          path: 'user',
          element: <UserProfile />,
          children: [
            {
              path: 'personal',
              element: <UserTabPersonal />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            },
            {
              path: 'settings',
              element: <UserTabSettings />
            }
          ]
        },
        {
          path: 'callback',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANIES_ROLES }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: '',
              element: <CallbackInfo />
            }
          ]
        },
        {
          path: 'companies-groups',
          element: (
            <AuthGuard props={{ permission: Permissions.MANAGE_COMPANIES_GROUPS }}>
              <Outlet />
            </AuthGuard>
          ),
          children: [
            {
              path: '',
              element: <CompaniesGroups />
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: '403',
          element: <MaintenanceError403 />
        }
      ]
    }
  ]
};

export default MainRoutes;
