import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// import { useState } from 'react';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //
// import { useState } from 'react';

// // material-ui
// import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// // assets
// import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';

// // ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleEditProfile: () => void;
}

const ProfileTab = ({ handleLogout, handleEditProfile }: Props) => {
  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
  //   setSelectedIndex(index);
  // };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton onClick={handleEditProfile}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Редактировать профиль" />
      </ListItemButton>
      {/* <ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton> */}
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Выход" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
