import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps } from 'react-joyride';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';

// types
import { MenuOrientation } from 'types/config';
import { useAppContext } from './context';
import { useMount } from 'react-use';
import useLocalStorage from '../../hooks/useLocalStorage';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const {
    setState,
    state: { run, stepIndex, steps }
  } = useAppContext();
  const navigate = useNavigate();
  const [localStorageValue, setLocalStorageValue] = useLocalStorage('base-tour', { passed: false });

  useMount(() => {
    setState({
      steps: [
        {
          target: '#roleslist',
          title: 'Начнём',
          content: <>Давайте создадим роли. Роли нужны для того, чтобы мы могли гибко управлять организационной структурой компании.</>,
          placement: 'bottom-start',
          placementBeacon: 'right'
        },
        {
          target: '#add-role-button',
          title: 'Пора добавить первую роль',
          content: <>Роли необходимы для ограничения доступа к функциям системы для разных пользователей</>,
          placement: 'bottom',
          placementBeacon: 'bottom'
        },
        {
          target: '#companieslist',
          title: 'Прекрасно!',
          content: (
            <>
              Вы успешно добавили роль. Пришла пора добавить точку продаж, это может быть дилерский центр, магазин или площадка для продаж
              авто с пробегом.
            </>
          ),
          placement: 'bottom-start',
          placementBeacon: 'right'
        },
        {
          target: '#add-company-button',
          title: 'Самое время добавить точку продаж',
          content: <>Точки продаж это самый необходимый элемент системы, так как точки продаж - это ваш дилерский центр или магазин</>,
          placement: 'bottom',
          placementBeacon: 'bottom'
        },
        {
          target: '#users-groupslist',
          title: 'Теперь добавляем группы',
          content: <>Группы больше всего похожи на отделы. Добавьте основные: отдел продаж, отдел сервиса и другие.</>,
          placement: 'bottom-start',
          placementBeacon: 'right'
        },
        {
          target: '#add-users-group-button',
          title: 'Добавляем первую группу',
          content: <>Вам нужно придумать название группы и привязать её к компании.</>,
          placement: 'bottom',
          placementBeacon: 'bottom'
        },
        {
          target: '#directionslist',
          title: 'Добавляем направления',
          content: (
            <>
              Направления - это способ связывания отдела и цели, с которой клиент пришёл к вам. Направления будут видны в интерфейсе
              администратора ресепшн.
            </>
          ),
          placement: 'bottom-start',
          placementBeacon: 'right'
        },
        {
          target: '#add-direction-button',
          title: 'Добавьте первые направления',
          content: (
            <>
              Направление представляет из себя связывание группы или конкретного специалиста с названием, отображаемым в панели
              администратора ресепшн.
            </>
          ),
          placement: 'bottom',
          placementBeacon: 'bottom'
        },
        {
          target: '#employeelist',
          title: 'Добавляем сотрудников',
          content: <>В этом разделе мы приглашаем сотрудников вашей компании в систему.</>,
          placement: 'bottom-start',
          placementBeacon: 'right'
        },
        {
          target: '#add-employee-button',
          title: 'Добавляем первого сотрудника',
          content: <>Для того чтобы добавить сотрудника нам потребуется его ФИО, Email, выбрать группу и привязать роль.</>,
          placement: 'bottom',
          placementBeacon: 'bottom'
        },
        {
          target: 'body',
          title: 'Ну вот и всё!',
          content: (
            <>
              Мы собрали все основные кусочки системы и готовы принимать первых клиентов нашими администраторами ресепшн. Хотите взглянуть
              на список клиентов уже сейчас?
            </>
          ),
          placement: 'center'
          // placementBeacon: 'left'
        }
      ]
    });
  });

  const handleCallback = (data: CallBackProps) => {
    const { index, type } = data;
    if (type === 'step:after') {
      if (index === 0) {
        navigate('/roles/list');
      }
      if (index === 2) {
        navigate('/companies/list');
      }
      if (index === 4) {
        navigate('/users-groups/list');
      }
      if (index === 6) {
        navigate('/directions/list');
      }
      if (index === 8) {
        navigate('/employee/list');
      }
      if (index === 10) {
        navigate('/clients/list');
        setState({ run: false });
        setLocalStorageValue({ passed: true });
      }
    }
  };
  useEffect(() => {
    if (localStorageValue?.passed !== true) {
      setState({ run: true });
    }
  }, [localStorageValue?.passed]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!matchDownXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownXL]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
          <Outlet />
          <Footer />
        </Container>
      </Box>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        locale={{
          back: 'Назад',
          close: 'Закрыть',
          last: 'Завершить',
          next: 'Далее',
          skip: 'Пропустить'
        }}
        hideBackButton
        hideCloseButton
        styles={{
          options: {
            // arrowColor: theme.palette.grey[50],
            // backgroundColor: theme.palette.background.default,
            // primaryColor: theme.palette.text.primary,
            // textColor: theme.palette.info.main,
            zIndex: 2000
          }
        }}
      />
    </Box>
  );
};

export default MainLayout;
