import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
};

export enum AuthContextEnum {
  AdminPanel = 'AdminPanel',
  UserApp = 'UserApp'
}

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user: UserModel;
};

export type BasicCompanyModel = {
  __typename?: 'BasicCompanyModel';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BasicGroupModel = {
  __typename?: 'BasicGroupModel';
  company: BasicCompanyModel;
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BasicUserModel = {
  __typename?: 'BasicUserModel';
  group: BasicGroupModel;
  groupId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CarLibraryItemGenerationModel = {
  __typename?: 'CarLibraryItemGenerationModel';
  id: Scalars['Int']['output'];
  model: CarLibraryItemModelModel;
  name: Scalars['String']['output'];
};

export type CarLibraryItemMakeModel = {
  __typename?: 'CarLibraryItemMakeModel';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CarLibraryItemModel = {
  __typename?: 'CarLibraryItemModel';
  id: Scalars['Int']['output'];
  modification: CarLibraryItemModificationModel;
  name: Scalars['String']['output'];
};

export type CarLibraryItemModelModel = {
  __typename?: 'CarLibraryItemModelModel';
  id: Scalars['Int']['output'];
  make: CarLibraryItemMakeModel;
  name: Scalars['String']['output'];
};

export type CarLibraryItemModificationModel = {
  __typename?: 'CarLibraryItemModificationModel';
  bodyType: Scalars['String']['output'];
  doors: Scalars['Int']['output'];
  driveType: Scalars['String']['output'];
  engineSize: Scalars['Float']['output'];
  fuelType: Scalars['String']['output'];
  generation: CarLibraryItemGenerationModel;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  power: Scalars['Int']['output'];
  transmission: Scalars['String']['output'];
  yearFrom: Scalars['Int']['output'];
  yearTo: Scalars['Int']['output'];
};

export type CarLibraryListModel = {
  __typename?: 'CarLibraryListModel';
  items: Array<CarLibraryItemModel>;
  total: Scalars['Int']['output'];
};

export type Client = {
  __typename?: 'Client';
  carName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type ClientDealDataEmployeeModel = {
  __typename?: 'ClientDealDataEmployeeModel';
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type ClientDealDataInteractionModel = {
  __typename?: 'ClientDealDataInteractionModel';
  complete: Scalars['Boolean']['output'];
  interactionType: DealInteractionType;
  scored: Scalars['Boolean']['output'];
};

export type ClientDealDataModel = {
  __typename?: 'ClientDealDataModel';
  car?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<ClientDealDataEmployeeModel>;
  id: Scalars['Int']['output'];
  interactions: Array<ClientDealDataInteractionModel>;
  isMet: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum ClientDealStatus {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export type ClientListParamsInput = {
  carName?: InputMaybe<Scalars['String']['input']>;
  dealStatus?: ClientDealStatus;
  groupName?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Float']['input'];
  visitedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  visitedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyCarModel = {
  __typename?: 'CompanyCarModel';
  brand?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  libraryCar?: Maybe<CarLibraryItemModel>;
  model?: Maybe<Scalars['String']['output']>;
};

export type CompanyDataDirectionModel = {
  __typename?: 'CompanyDataDirectionModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  visitorsDirection: Scalars['Boolean']['output'];
};

export type CompanyDataModel = {
  __typename?: 'CompanyDataModel';
  availableCars: Array<CompanyCarModel>;
  directions: Array<CompanyDataDirectionModel>;
  name: Scalars['String']['output'];
};

export type CompleteInvitationInfoModel = {
  __typename?: 'CompleteInvitationInfoModel';
  group: Scalars['String']['output'];
  role: Scalars['String']['output'];
  sentByUser: Scalars['String']['output'];
};

export type CompleteInvitationInput = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export enum DealInteractionStatus {
  Failed = 'FAILED',
  Progress = 'PROGRESS',
  Success = 'SUCCESS',
  Transferred = 'TRANSFERRED'
}

export enum DealInteractionType {
  AdditionalDetails = 'ADDITIONAL_DETAILS',
  Delivery = 'DELIVERY',
  Demo = 'DEMO',
  Payment = 'PAYMENT',
  TradeIn = 'TRADE_IN'
}

export enum DealMeta {
  AccessoriesPrice = 'AccessoriesPrice',
  CarReady = 'CarReady',
  LoanBanksReply = 'LoanBanksReply',
  LoanContractSigned = 'LoanContractSigned',
  LoanRequestFilled = 'LoanRequestFilled',
  PaymentLoanWithInitialAmount = 'PaymentLoanWithInitialAmount',
  PaymentMethod = 'PaymentMethod',
  TestDriveComplete = 'TestDriveComplete',
  TradeIn = 'TradeIn',
  TradeInBrand = 'TradeInBrand',
  TradeInClientConfirmed = 'TradeInClientConfirmed',
  TradeInModel = 'TradeInModel',
  TradeInPrice = 'TradeInPrice',
  WorklistUrl = 'WorklistUrl'
}

export type DealMetaData = {
  __typename?: 'DealMetaData';
  key: DealMeta;
  value: Scalars['String']['output'];
};

export type DealMetaModel = {
  __typename?: 'DealMetaModel';
  key: DealMeta;
  value: Scalars['String']['output'];
};

export type DealUpdatedModel = {
  __typename?: 'DealUpdatedModel';
  id: Scalars['Int']['output'];
};

export enum DirectionType {
  Client = 'CLIENT',
  Visitor = 'VISITOR'
}

export type EditRoleInput = {
  name: Scalars['String']['input'];
  permissions: Array<Scalars['Int']['input']>;
  users: Array<Scalars['Int']['input']>;
};

export type EmployeeDealDetails = {
  __typename?: 'EmployeeDealDetails';
  car?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  interactions: Array<EmployeeDealDetailsInteraction>;
  meta: Array<DealMetaData>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status: ClientDealStatus;
  visitedAt: Scalars['DateTime']['output'];
};

export type EmployeeDealDetailsInteraction = {
  __typename?: 'EmployeeDealDetailsInteraction';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  status: DealInteractionStatus;
  type: DealInteractionType;
  userId?: Maybe<Scalars['Int']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type EmployeeWorkConfirmationDealInfo = {
  __typename?: 'EmployeeWorkConfirmationDealInfo';
  accompanying?: Maybe<Scalars['String']['output']>;
  car?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  interactionType: DealInteractionType;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ListCarsLibraryInput = {
  carComplectationId?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ListDealsClientModel = {
  __typename?: 'ListDealsClientModel';
  car?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  meta: Array<DealMetaModel>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  salesman?: Maybe<Scalars['String']['output']>;
  visitedAt: Scalars['DateTime']['output'];
};

export type ListDealsFilterInput = {
  status?: InputMaybe<ClientDealStatus>;
};

export type ListEmployeeForNextDealStepItemModel = {
  __typename?: 'ListEmployeeForNextDealStepItemModel';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ListEmployeeItemModel = {
  __typename?: 'ListEmployeeItemModel';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LoginInput = {
  emailOrPhone: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ManageCallbackHistoryItemModel = {
  __typename?: 'ManageCallbackHistoryItemModel';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  responseBody?: Maybe<Scalars['String']['output']>;
  responseStatus: Scalars['Int']['output'];
};

export type ManageCallbackHistoryModel = {
  __typename?: 'ManageCallbackHistoryModel';
  items: Array<ManageCallbackHistoryItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageCallbackHistoryParamsInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type ManageCallbackModel = {
  __typename?: 'ManageCallbackModel';
  enabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ManageCarsEditItemInput = {
  brand: Scalars['String']['input'];
  carComplectationId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  model: Scalars['String']['input'];
};

export type ManageCarsItemModel = {
  __typename?: 'ManageCarsItemModel';
  brand?: Maybe<Scalars['String']['output']>;
  carComplectation?: Maybe<CarLibraryItemModel>;
  carComplectationId?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  model?: Maybe<Scalars['String']['output']>;
};

export type ManageCarsListInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ManageCarsListModel = {
  __typename?: 'ManageCarsListModel';
  items: Array<ManageCarsItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageClientsFullItemAccompanyingModel = {
  __typename?: 'ManageClientsFullItemAccompanyingModel';
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ManageClientsFullItemInteractionModel = {
  __typename?: 'ManageClientsFullItemInteractionModel';
  clientScore: Scalars['Int']['output'];
  employee?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  status: DealInteractionStatus;
  type: DealInteractionType;
};

export type ManageClientsFullItemMetaModel = {
  __typename?: 'ManageClientsFullItemMetaModel';
  key: DealMeta;
  value: Scalars['String']['output'];
};

export type ManageClientsFullItemModel = {
  __typename?: 'ManageClientsFullItemModel';
  accompanying?: Maybe<ManageClientsFullItemAccompanyingModel>;
  appointment: Scalars['Boolean']['output'];
  car?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  interactions: Array<ManageClientsFullItemInteractionModel>;
  meta: Array<ManageClientsFullItemMetaModel>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  visitedAt: Scalars['DateTime']['output'];
};

export type ManageClientsListItemModel = {
  __typename?: 'ManageClientsListItemModel';
  car?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  visitedAt: Scalars['DateTime']['output'];
};

export type ManageClientsListModel = {
  __typename?: 'ManageClientsListModel';
  items: Array<ManageClientsListItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageCompaniesEditItemInput = {
  name: Scalars['String']['input'];
};

export type ManageCompaniesGroupsEditItemInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  user: ManageCompaniesGroupsEditItemUserInput;
};

export type ManageCompaniesGroupsEditItemUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type ManageCompaniesGroupsItemModel = {
  __typename?: 'ManageCompaniesGroupsItemModel';
  clients: Scalars['Int']['output'];
  companies: Scalars['Int']['output'];
  frozenAt?: Maybe<Scalars['DateTime']['output']>;
  frozenReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  techAdminEmail: Scalars['String']['output'];
  techAdminName: Scalars['String']['output'];
  techAdminPhone: Scalars['String']['output'];
  users: Scalars['Int']['output'];
};

export type ManageCompaniesGroupsListInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type ManageCompaniesGroupsListModel = {
  __typename?: 'ManageCompaniesGroupsListModel';
  items: Array<ManageCompaniesGroupsItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageCompaniesItemModel = {
  __typename?: 'ManageCompaniesItemModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ManageCompaniesListInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ManageCompaniesListModel = {
  __typename?: 'ManageCompaniesListModel';
  items: Array<ManageCompaniesItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageCompaniesSelfServiceInfoItemModel = {
  __typename?: 'ManageCompaniesSelfServiceInfoItemModel';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ManageDirectionsEditItemInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  groupId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: DirectionType;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ManageDirectionsItemModel = {
  __typename?: 'ManageDirectionsItemModel';
  group: BasicGroupModel;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: DirectionType;
  user?: Maybe<BasicUserModel>;
};

export type ManageDirectionsListInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ManageDirectionsListModel = {
  __typename?: 'ManageDirectionsListModel';
  items: Array<ManageDirectionsItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageEmployeeEditItemInput = {
  email: Scalars['String']['input'];
  groupId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  roleId: Scalars['Float']['input'];
};

export type ManageEmployeeInviteInput = {
  email: Scalars['String']['input'];
  groupId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  roleId: Scalars['Float']['input'];
};

export type ManageEmployeeItemModel = {
  __typename?: 'ManageEmployeeItemModel';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  group?: Maybe<ManageGroupsItemGroupModel>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  role: ManageGroupsItemRoleModel;
  status: UserStatus;
};

export type ManageEmployeeListGroupsInput = {
  name: Scalars['String']['input'];
};

export type ManageEmployeeListInput = {
  createdAtGte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Float']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
};

export type ManageEmployeeListModel = {
  __typename?: 'ManageEmployeeListModel';
  items: Array<ManageEmployeeItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageEmployeeListRolesInput = {
  name: Scalars['String']['input'];
};

export type ManageGroupsEditItemInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  users: Array<Scalars['Int']['input']>;
};

export type ManageGroupsGroupUser = {
  __typename?: 'ManageGroupsGroupUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ManageGroupsItemFullModel = {
  __typename?: 'ManageGroupsItemFullModel';
  companyId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  users: Array<ManageGroupsGroupUser>;
};

export type ManageGroupsItemGroupModel = {
  __typename?: 'ManageGroupsItemGroupModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ManageGroupsItemModel = {
  __typename?: 'ManageGroupsItemModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  usersCount: Scalars['Int']['output'];
};

export type ManageGroupsItemRoleModel = {
  __typename?: 'ManageGroupsItemRoleModel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ManageGroupsListInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ManageGroupsListModel = {
  __typename?: 'ManageGroupsListModel';
  items: Array<ManageGroupsItemModel>;
  total: Scalars['Int']['output'];
};

export type ManageUpdateCallbackInput = {
  enabled: Scalars['Boolean']['input'];
  updateKey: Scalars['Boolean']['input'];
  url: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  assignNewClientToMe: Scalars['Boolean']['output'];
  changePassword: Scalars['Boolean']['output'];
  clientCome: Visitor;
  clientRateInteraction: Scalars['Boolean']['output'];
  completeInvitation: UserModel;
  createCompany: ManageCompaniesItemModel;
  createDirection: ManageDirectionsItemModel;
  createRole: Role;
  createUsersGroup: ManageGroupsItemModel;
  deleteCompany: Scalars['Boolean']['output'];
  deleteDirection: Scalars['Boolean']['output'];
  deleteRole: Scalars['Boolean']['output'];
  deleteUsersGroup: Scalars['Boolean']['output'];
  employeeDeclineWorkStep: Scalars['Boolean']['output'];
  finalizeRegistration: AuthPayload;
  loginEmailOrPhone: UserModel;
  logout: Scalars['Boolean']['output'];
  manageClientsArchiveDeal: Scalars['Boolean']['output'];
  manageClientsUnarchiveDeal: Scalars['Boolean']['output'];
  manageCreateCar: ManageCarsItemModel;
  manageDeleteCar: Scalars['Boolean']['output'];
  manageDeleteCompaniesGroup: Scalars['Boolean']['output'];
  manageEmployeeDelete: Scalars['Boolean']['output'];
  manageEmployeeInvite: Scalars['Boolean']['output'];
  manageEmployeeUpdate: Scalars['Boolean']['output'];
  manageFreezeCompaniesGroup: Scalars['Boolean']['output'];
  manageUnfreezeCompaniesGroup: Scalars['Boolean']['output'];
  manageUpdateCallback: ManageCallbackModel;
  manageUpdateCar: ManageCarsItemModel;
  manageUpdateCompaniesGroup: ManageCompaniesGroupsItemModel;
  markNewClientAsMetByMe: Scalars['Boolean']['output'];
  register: RegistrationStatusEnum;
  requestResetPassword: Scalars['Boolean']['output'];
  resendPhoneConfirmationCode: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  updateCompany: ManageCompaniesItemModel;
  updateDealAccessoriesPriceStep: Scalars['Boolean']['output'];
  updateDealAccessoriesSpecialistNextStep: Scalars['Boolean']['output'];
  updateDealAppraiserNextStep: Scalars['Boolean']['output'];
  updateDealDemoStep: Scalars['Boolean']['output'];
  updateDealLoanDetailsStep: Scalars['Boolean']['output'];
  updateDealLoanOfficerNextStep: Scalars['Boolean']['output'];
  updateDealPaymentStep: Scalars['Boolean']['output'];
  updateDealPreSaleDetailsStep: Scalars['Boolean']['output'];
  updateDealPreSaleStep: Scalars['Boolean']['output'];
  updateDealSalesmanCompleteDeal: Scalars['Boolean']['output'];
  updateDealSalesmanNextStep: Scalars['Boolean']['output'];
  updateDealSalesmanTransferToUser: Scalars['Boolean']['output'];
  updateDealTradeInDetailsStep: Scalars['Boolean']['output'];
  updateDealTradeInStep: Scalars['Boolean']['output'];
  updateDirection: ManageDirectionsItemModel;
  updateProfile: Scalars['Boolean']['output'];
  updateRole: Role;
  updateUsersGroup: ManageGroupsItemModel;
  verifyEmail: RegistrationStatusEnum;
  verifyPhone: RegistrationStatusEnum;
};

export type MutationAssignNewClientToMeArgs = {
  clientId: Scalars['Int']['input'];
};

export type MutationChangePasswordArgs = {
  input: PasswordChangeInput;
};

export type MutationClientComeArgs = {
  input: NewClient;
};

export type MutationClientRateInteractionArgs = {
  dealId: Scalars['Int']['input'];
  interactionType: DealInteractionType;
  score: Scalars['Int']['input'];
};

export type MutationCompleteInvitationArgs = {
  input: CompleteInvitationInput;
};

export type MutationCreateCompanyArgs = {
  input: ManageCompaniesEditItemInput;
};

export type MutationCreateDirectionArgs = {
  input: ManageDirectionsEditItemInput;
};

export type MutationCreateRoleArgs = {
  input: EditRoleInput;
};

export type MutationCreateUsersGroupArgs = {
  input: ManageGroupsEditItemInput;
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteDirectionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteUsersGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationEmployeeDeclineWorkStepArgs = {
  dealId: Scalars['Int']['input'];
};

export type MutationFinalizeRegistrationArgs = {
  password: Scalars['String']['input'];
};

export type MutationLoginEmailOrPhoneArgs = {
  input: LoginInput;
};

export type MutationManageClientsArchiveDealArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageClientsUnarchiveDealArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageCreateCarArgs = {
  input: ManageCarsEditItemInput;
};

export type MutationManageDeleteCarArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageDeleteCompaniesGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageEmployeeDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageEmployeeInviteArgs = {
  input: ManageEmployeeInviteInput;
};

export type MutationManageEmployeeUpdateArgs = {
  id: Scalars['Int']['input'];
  input: ManageEmployeeEditItemInput;
};

export type MutationManageFreezeCompaniesGroupArgs = {
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type MutationManageUnfreezeCompaniesGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManageUpdateCallbackArgs = {
  input: ManageUpdateCallbackInput;
};

export type MutationManageUpdateCarArgs = {
  id: Scalars['Int']['input'];
  input: ManageCarsEditItemInput;
};

export type MutationManageUpdateCompaniesGroupArgs = {
  id: Scalars['Int']['input'];
  input: ManageCompaniesGroupsEditItemInput;
};

export type MutationMarkNewClientAsMetByMeArgs = {
  clientId: Scalars['Int']['input'];
};

export type MutationRegisterArgs = {
  input: RegistrationInput;
};

export type MutationRequestResetPasswordArgs = {
  authContext: AuthContextEnum;
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  input: PasswordResetInput;
};

export type MutationUpdateCompanyArgs = {
  id: Scalars['Int']['input'];
  input: ManageCompaniesEditItemInput;
};

export type MutationUpdateDealAccessoriesPriceStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealAccessoriesInput;
};

export type MutationUpdateDealAccessoriesSpecialistNextStepArgs = {
  dealId: Scalars['Int']['input'];
};

export type MutationUpdateDealAppraiserNextStepArgs = {
  dealId: Scalars['Int']['input'];
};

export type MutationUpdateDealDemoStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealDemoInput;
};

export type MutationUpdateDealLoanDetailsStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealPaymentLoanDetailsInput;
};

export type MutationUpdateDealLoanOfficerNextStepArgs = {
  dealId: Scalars['Int']['input'];
};

export type MutationUpdateDealPaymentStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealPaymentInput;
};

export type MutationUpdateDealPreSaleDetailsStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealPreSaleInput;
};

export type MutationUpdateDealPreSaleStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealPreSaleInput;
};

export type MutationUpdateDealSalesmanCompleteDealArgs = {
  dealId: Scalars['Int']['input'];
};

export type MutationUpdateDealSalesmanNextStepArgs = {
  dealId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationUpdateDealSalesmanTransferToUserArgs = {
  dealId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationUpdateDealTradeInDetailsStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealTradeInDetailsInput;
};

export type MutationUpdateDealTradeInStepArgs = {
  dealId: Scalars['Int']['input'];
  input: UpdateDealTradeInInput;
};

export type MutationUpdateDirectionArgs = {
  id: Scalars['Int']['input'];
  input: ManageDirectionsEditItemInput;
};

export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['Int']['input'];
  input: EditRoleInput;
};

export type MutationUpdateUsersGroupArgs = {
  id: Scalars['Int']['input'];
  input: ManageGroupsEditItemInput;
};

export type MutationVerifyEmailArgs = {
  code: Scalars['String']['input'];
};

export type MutationVerifyPhoneArgs = {
  code: Scalars['String']['input'];
};

export type NewClient = {
  accompanying?: InputMaybe<NewClientAccompanying>;
  appointment?: InputMaybe<Scalars['Boolean']['input']>;
  bindExistingClient?: InputMaybe<Scalars['Boolean']['input']>;
  car?: InputMaybe<Scalars['String']['input']>;
  companyCarId?: InputMaybe<Scalars['Int']['input']>;
  directionId: Scalars['Int']['input'];
  fullName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type NewClientAccompanying = {
  fullName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type NewClientInfoModel = {
  __typename?: 'NewClientInfoModel';
  accompanyingName?: Maybe<Scalars['String']['output']>;
  accompanyingPhone?: Maybe<Scalars['String']['output']>;
  appointment: Scalars['Boolean']['output'];
  car?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isAssigned: Scalars['Boolean']['output'];
  isMet: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  visitedAt: Scalars['DateTime']['output'];
};

export type PasswordChangeInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type PasswordResetInput = {
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProfileUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  allPermissions: Array<Permission>;
  clientDealData: ClientDealDataModel;
  completeInvitationInfo: CompleteInvitationInfoModel;
  deskCompanyData: CompanyDataModel;
  employeeDealDetails: EmployeeDealDetails;
  employeeWorkConfirmationDealInfo: EmployeeWorkConfirmationDealInfo;
  getCompanies: ManageCompaniesListModel;
  getCurrentUser: UserModel;
  getManageEmployee: ManageEmployeeListModel;
  getManageEmployeeGroupsList: Array<ManageGroupsItemGroupModel>;
  getManageEmployeeRolesList: Array<ManageGroupsItemRoleModel>;
  getManageGroupsToAssignToDirection: Array<BasicGroupModel>;
  getManageUsersToAssignToDirection: Array<BasicUserModel>;
  getManageUsersToAssignToGroup: Array<ManageGroupsGroupUser>;
  getRole: RoleFull;
  getRoles: Roles;
  getSelfServiceInfo: Array<ManageCompaniesSelfServiceInfoItemModel>;
  getUsersGroup: ManageGroupsItemFullModel;
  getUsersGroups: ManageGroupsListModel;
  getUsersToAssign: Array<RoleUser>;
  listDeals: Array<ListDealsClientModel>;
  listEmployee: Array<ListEmployeeItemModel>;
  listEmployeeForTransfer: Array<ListEmployeeForNextDealStepItemModel>;
  manageCallback: ManageCallbackModel;
  manageCallbackHistory: ManageCallbackHistoryModel;
  manageClientsDownloadLink: Scalars['String']['output'];
  manageClientsFullItem: ManageClientsFullItemModel;
  manageClientsList: ManageClientsListModel;
  manageCompaniesGroupsList: ManageCompaniesGroupsListModel;
  manageDirectionsList: ManageDirectionsListModel;
  manageGetCars: ManageCarsListModel;
  manageGetCarsLibrary: CarLibraryListModel;
  newClientInfo: NewClientInfoModel;
  receptionCompanyData: CompanyDataModel;
  visitors: Array<Visitor>;
};

export type QueryClientDealDataArgs = {
  key: Scalars['String']['input'];
};

export type QueryCompleteInvitationInfoArgs = {
  code: Scalars['String']['input'];
};

export type QueryDeskCompanyDataArgs = {
  companyId: Scalars['Int']['input'];
};

export type QueryEmployeeDealDetailsArgs = {
  dealId: Scalars['Int']['input'];
};

export type QueryEmployeeWorkConfirmationDealInfoArgs = {
  dealId: Scalars['Int']['input'];
};

export type QueryGetCompaniesArgs = {
  input: ManageCompaniesListInput;
};

export type QueryGetManageEmployeeArgs = {
  input: ManageEmployeeListInput;
};

export type QueryGetManageEmployeeGroupsListArgs = {
  input: ManageEmployeeListGroupsInput;
};

export type QueryGetManageEmployeeRolesListArgs = {
  input: ManageEmployeeListRolesInput;
};

export type QueryGetManageGroupsToAssignToDirectionArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetManageUsersToAssignToDirectionArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetManageUsersToAssignToGroupArgs = {
  query: Scalars['String']['input'];
};

export type QueryGetRoleArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetRolesArgs = {
  input: RolesParamsInput;
};

export type QueryGetUsersGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUsersGroupsArgs = {
  input: ManageGroupsListInput;
};

export type QueryGetUsersToAssignArgs = {
  query: Scalars['String']['input'];
};

export type QueryListDealsArgs = {
  input: ListDealsFilterInput;
};

export type QueryListEmployeeArgs = {
  query: Scalars['String']['input'];
};

export type QueryListEmployeeForTransferArgs = {
  dealId: Scalars['Int']['input'];
};

export type QueryManageCallbackHistoryArgs = {
  input: ManageCallbackHistoryParamsInput;
};

export type QueryManageClientsFullItemArgs = {
  id: Scalars['Int']['input'];
};

export type QueryManageClientsListArgs = {
  input: ClientListParamsInput;
};

export type QueryManageCompaniesGroupsListArgs = {
  input: ManageCompaniesGroupsListInput;
};

export type QueryManageDirectionsListArgs = {
  input: ManageDirectionsListInput;
};

export type QueryManageGetCarsArgs = {
  input: ManageCarsListInput;
};

export type QueryManageGetCarsLibraryArgs = {
  input: ListCarsLibraryInput;
};

export type QueryVisitorsArgs = {
  queryName?: InputMaybe<Scalars['String']['input']>;
};

export type RegistrationInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export enum RegistrationStatusEnum {
  BothVerified = 'BothVerified',
  EmailVerified = 'EmailVerified',
  NoneVerified = 'NoneVerified',
  PhoneVerified = 'PhoneVerified'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  usersCount: Scalars['Float']['output'];
};

export type RoleFull = {
  __typename?: 'RoleFull';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  users: Array<RoleUser>;
};

export type RoleUser = {
  __typename?: 'RoleUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Roles = {
  __typename?: 'Roles';
  items: Array<Role>;
  total: Scalars['Int']['output'];
};

export type RolesParamsInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  dealUpdated: DealUpdatedModel;
};

export type UpdateDealAccessoriesInput = {
  price: Scalars['Float']['input'];
};

export type UpdateDealDemoInput = {
  car: Scalars['String']['input'];
  isTestDriveComplete: Scalars['Boolean']['input'];
};

export type UpdateDealPaymentInput = {
  loan: Scalars['Boolean']['input'];
  withInitialAmount: Scalars['Boolean']['input'];
};

export type UpdateDealPaymentLoanDetailsInput = {
  banksApproved?: InputMaybe<Scalars['Boolean']['input']>;
  contractSigned?: InputMaybe<Scalars['Boolean']['input']>;
  isRequestFilled: Scalars['Boolean']['input'];
};

export type UpdateDealPreSaleInput = {
  ready: Scalars['Boolean']['input'];
};

export type UpdateDealTradeInDetailsInput = {
  brand: Scalars['String']['input'];
  confirmed: Scalars['Boolean']['input'];
  model: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type UpdateDealTradeInInput = {
  tradeIn: Scalars['Boolean']['input'];
};

export type UserModel = {
  __typename?: 'UserModel';
  company?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  role: Scalars['String']['output'];
  subscriptions: Array<Scalars['String']['output']>;
  tgBotKey?: Maybe<Scalars['String']['output']>;
};

export enum UserStatus {
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type Visitor = {
  __typename?: 'Visitor';
  accompanyingName?: Maybe<Scalars['String']['output']>;
  accompanyingPhone?: Maybe<Scalars['String']['output']>;
  appointment?: Maybe<Scalars['Boolean']['output']>;
  client?: Maybe<Client>;
  direction?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  visitedAt: Scalars['DateTime']['output'];
};

export type LoginEmailOrPhoneMutationVariables = Exact<{
  emailOrPhone: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginEmailOrPhoneMutation = {
  __typename?: 'Mutation';
  loginEmailOrPhone: {
    __typename?: 'UserModel';
    id: number;
    name: string;
    permissions: Array<string>;
    role: string;
    group?: string | null;
    company?: string | null;
    subscriptions: Array<string>;
  };
};

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  authContext: AuthContextEnum;
}>;

export type RequestResetPasswordMutation = { __typename?: 'Mutation'; requestResetPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  input: PasswordResetInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: boolean };

export type RegisterMutationVariables = Exact<{
  input: RegistrationInput;
}>;

export type RegisterMutation = { __typename?: 'Mutation'; register: RegistrationStatusEnum };

export type ResendPhoneConfirmationCodeMutationVariables = Exact<{ [key: string]: never }>;

export type ResendPhoneConfirmationCodeMutation = { __typename?: 'Mutation'; resendPhoneConfirmationCode: boolean };

export type VerifyPhoneMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifyPhoneMutation = { __typename?: 'Mutation'; verifyPhone: RegistrationStatusEnum };

export type VerifyEmailMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation'; verifyEmail: RegistrationStatusEnum };

export type FinalizeRegistrationMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;

export type FinalizeRegistrationMutation = {
  __typename?: 'Mutation';
  finalizeRegistration: {
    __typename?: 'AuthPayload';
    user: {
      __typename?: 'UserModel';
      id: number;
      name: string;
      permissions: Array<string>;
      role: string;
      group?: string | null;
      company?: string | null;
    };
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type CreateRoleMutationVariables = Exact<{
  input: EditRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: {
    __typename?: 'Role';
    id: string;
    name: string;
    usersCount: number;
    permissions: Array<{ __typename?: 'Permission'; id: string; name: string }>;
  };
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: EditRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: {
    __typename?: 'Role';
    id: string;
    name: string;
    usersCount: number;
    permissions: Array<{ __typename?: 'Permission'; id: string; name: string }>;
  };
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteRoleMutation = { __typename?: 'Mutation'; deleteRole: boolean };

export type CreateCompanyMutationVariables = Exact<{
  input: ManageCompaniesEditItemInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: { __typename?: 'ManageCompaniesItemModel'; id: string; name: string };
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageCompaniesEditItemInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: { __typename?: 'ManageCompaniesItemModel'; id: string; name: string };
};

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteCompanyMutation = { __typename?: 'Mutation'; deleteCompany: boolean };

export type CreateUsersGroupMutationVariables = Exact<{
  input: ManageGroupsEditItemInput;
}>;

export type CreateUsersGroupMutation = {
  __typename?: 'Mutation';
  createUsersGroup: { __typename?: 'ManageGroupsItemModel'; id: string; name: string; usersCount: number };
};

export type UpdateUsersGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageGroupsEditItemInput;
}>;

export type UpdateUsersGroupMutation = {
  __typename?: 'Mutation';
  updateUsersGroup: { __typename?: 'ManageGroupsItemModel'; id: string; name: string; usersCount: number };
};

export type DeleteUsersGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteUsersGroupMutation = { __typename?: 'Mutation'; deleteUsersGroup: boolean };

export type ManageEmployeeInviteMutationVariables = Exact<{
  input: ManageEmployeeInviteInput;
}>;

export type ManageEmployeeInviteMutation = { __typename?: 'Mutation'; manageEmployeeInvite: boolean };

export type ManageEmployeeUpdateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageEmployeeEditItemInput;
}>;

export type ManageEmployeeUpdateMutation = { __typename?: 'Mutation'; manageEmployeeUpdate: boolean };

export type ManageEmployeeDeleteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageEmployeeDeleteMutation = { __typename?: 'Mutation'; manageEmployeeDelete: boolean };

export type UpdateProfileMutationVariables = Exact<{
  input: ProfileUpdateInput;
}>;

export type UpdateProfileMutation = { __typename?: 'Mutation'; updateProfile: boolean };

export type ChangePasswordMutationVariables = Exact<{
  input: PasswordChangeInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean };

export type CreateDirectionMutationVariables = Exact<{
  input: ManageDirectionsEditItemInput;
}>;

export type CreateDirectionMutation = {
  __typename?: 'Mutation';
  createDirection: {
    __typename?: 'ManageDirectionsItemModel';
    id: string;
    name: string;
    type: DirectionType;
    group: { __typename?: 'BasicGroupModel'; id: number; name: string };
    user?: { __typename?: 'BasicUserModel'; id: number; name: string } | null;
  };
};

export type UpdateDirectionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageDirectionsEditItemInput;
}>;

export type UpdateDirectionMutation = {
  __typename?: 'Mutation';
  updateDirection: {
    __typename?: 'ManageDirectionsItemModel';
    id: string;
    name: string;
    type: DirectionType;
    group: { __typename?: 'BasicGroupModel'; id: number; name: string };
    user?: { __typename?: 'BasicUserModel'; id: number; name: string } | null;
  };
};

export type DeleteDirectionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteDirectionMutation = { __typename?: 'Mutation'; deleteDirection: boolean };

export type ManageUpdateCallbackMutationVariables = Exact<{
  input: ManageUpdateCallbackInput;
}>;

export type ManageUpdateCallbackMutation = {
  __typename?: 'Mutation';
  manageUpdateCallback: { __typename?: 'ManageCallbackModel'; url: string; key: string; enabled: boolean };
};

export type ManageUpdateCompaniesGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageCompaniesGroupsEditItemInput;
}>;

export type ManageUpdateCompaniesGroupMutation = {
  __typename?: 'Mutation';
  manageUpdateCompaniesGroup: {
    __typename?: 'ManageCompaniesGroupsItemModel';
    id: string;
    name?: string | null;
    frozenAt?: any | null;
    frozenReason?: string | null;
    users: number;
    companies: number;
    clients: number;
    techAdminName: string;
    techAdminEmail: string;
    techAdminPhone: string;
  };
};

export type ManageFreezeCompaniesGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
}>;

export type ManageFreezeCompaniesGroupMutation = { __typename?: 'Mutation'; manageFreezeCompaniesGroup: boolean };

export type ManageUnfreezeCompaniesGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageUnfreezeCompaniesGroupMutation = { __typename?: 'Mutation'; manageUnfreezeCompaniesGroup: boolean };

export type ManageDeleteCompaniesGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageDeleteCompaniesGroupMutation = { __typename?: 'Mutation'; manageDeleteCompaniesGroup: boolean };

export type CreateCarMutationVariables = Exact<{
  input: ManageCarsEditItemInput;
}>;

export type CreateCarMutation = {
  __typename?: 'Mutation';
  manageCreateCar: {
    __typename?: 'ManageCarsItemModel';
    id: string;
    brand?: string | null;
    model?: string | null;
    companyId: number;
    carComplectationId?: number | null;
    carComplectation?: {
      __typename?: 'CarLibraryItemModel';
      id: number;
      name: string;
      modification: {
        __typename?: 'CarLibraryItemModificationModel';
        id: number;
        name: string;
        yearFrom: number;
        yearTo: number;
        fuelType: string;
        driveType: string;
        transmission: string;
        power: number;
        engineSize: number;
        bodyType: string;
        doors: number;
        generation: {
          __typename?: 'CarLibraryItemGenerationModel';
          name: string;
          model: { __typename?: 'CarLibraryItemModelModel'; name: string; make: { __typename?: 'CarLibraryItemMakeModel'; name: string } };
        };
      };
    } | null;
  };
};

export type UpdateCarMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: ManageCarsEditItemInput;
}>;

export type UpdateCarMutation = {
  __typename?: 'Mutation';
  manageUpdateCar: {
    __typename?: 'ManageCarsItemModel';
    id: string;
    brand?: string | null;
    model?: string | null;
    companyId: number;
    carComplectationId?: number | null;
    carComplectation?: {
      __typename?: 'CarLibraryItemModel';
      id: number;
      name: string;
      modification: {
        __typename?: 'CarLibraryItemModificationModel';
        id: number;
        name: string;
        yearFrom: number;
        yearTo: number;
        fuelType: string;
        driveType: string;
        transmission: string;
        power: number;
        engineSize: number;
        bodyType: string;
        doors: number;
        generation: {
          __typename?: 'CarLibraryItemGenerationModel';
          name: string;
          model: { __typename?: 'CarLibraryItemModelModel'; name: string; make: { __typename?: 'CarLibraryItemMakeModel'; name: string } };
        };
      };
    } | null;
  };
};

export type DeleteCarMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteCarMutation = { __typename?: 'Mutation'; manageDeleteCar: boolean };

export type ManageClientsArchiveDealMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageClientsArchiveDealMutation = { __typename?: 'Mutation'; manageClientsArchiveDeal: boolean };

export type ManageClientsUnarchiveDealMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageClientsUnarchiveDealMutation = { __typename?: 'Mutation'; manageClientsUnarchiveDeal: boolean };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser: {
    __typename?: 'UserModel';
    id: number;
    name: string;
    email: string;
    phone: string;
    image?: string | null;
    permissions: Array<string>;
    role: string;
    group?: string | null;
    company?: string | null;
    subscriptions: Array<string>;
  };
};

export type RolesQueryVariables = Exact<{
  input: RolesParamsInput;
}>;

export type RolesQuery = {
  __typename?: 'Query';
  getRoles: {
    __typename?: 'Roles';
    total: number;
    items: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      usersCount: number;
      permissions: Array<{ __typename?: 'Permission'; id: string; name: string }>;
    }>;
  };
};

export type RoleFullQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type RoleFullQuery = {
  __typename?: 'Query';
  getRole: {
    __typename?: 'RoleFull';
    id: string;
    name: string;
    permissions: Array<{ __typename?: 'Permission'; id: string; name: string }>;
    users: Array<{ __typename?: 'RoleUser'; id: string; name: string }>;
  };
};

export type AllPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllPermissionsQuery = { __typename?: 'Query'; allPermissions: Array<{ __typename?: 'Permission'; id: string; name: string }> };

export type GetUsersToAssignQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type GetUsersToAssignQuery = {
  __typename?: 'Query';
  getUsersToAssign: Array<{ __typename?: 'RoleUser'; id: string; name: string }>;
};

export type ManageCompaniesQueryVariables = Exact<{
  input: ManageCompaniesListInput;
}>;

export type ManageCompaniesQuery = {
  __typename?: 'Query';
  getCompanies: {
    __typename?: 'ManageCompaniesListModel';
    total: number;
    items: Array<{ __typename?: 'ManageCompaniesItemModel'; id: string; name: string }>;
  };
};

export type ManageUsersGroupsQueryVariables = Exact<{
  input: ManageGroupsListInput;
}>;

export type ManageUsersGroupsQuery = {
  __typename?: 'Query';
  getUsersGroups: {
    __typename?: 'ManageGroupsListModel';
    total: number;
    items: Array<{ __typename?: 'ManageGroupsItemModel'; id: string; name: string; usersCount: number }>;
  };
};

export type ManageUsersGroupFullQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageUsersGroupFullQuery = {
  __typename?: 'Query';
  getUsersGroup: {
    __typename?: 'ManageGroupsItemFullModel';
    id: string;
    name: string;
    companyId: number;
    users: Array<{ __typename?: 'ManageGroupsGroupUser'; id: string; name: string }>;
  };
};

export type GetManageUsersToAssignToGroupQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type GetManageUsersToAssignToGroupQuery = {
  __typename?: 'Query';
  getManageUsersToAssignToGroup: Array<{ __typename?: 'ManageGroupsGroupUser'; id: string; name: string }>;
};

export type ManageEmployeeQueryVariables = Exact<{
  input: ManageEmployeeListInput;
}>;

export type ManageEmployeeQuery = {
  __typename?: 'Query';
  getManageEmployee: {
    __typename?: 'ManageEmployeeListModel';
    total: number;
    items: Array<{
      __typename?: 'ManageEmployeeItemModel';
      id: string;
      name: string;
      email: string;
      phone: string;
      status: UserStatus;
      createdAt: any;
      role: { __typename?: 'ManageGroupsItemRoleModel'; id: string; name: string };
      group?: { __typename?: 'ManageGroupsItemGroupModel'; id: string; name: string } | null;
    }>;
  };
};

export type GetManageEmployeeRolesListQueryVariables = Exact<{
  input: ManageEmployeeListRolesInput;
}>;

export type GetManageEmployeeRolesListQuery = {
  __typename?: 'Query';
  getManageEmployeeRolesList: Array<{ __typename?: 'ManageGroupsItemRoleModel'; id: string; name: string }>;
};

export type GetManageEmployeeGroupsListQueryVariables = Exact<{
  input: ManageEmployeeListGroupsInput;
}>;

export type GetManageEmployeeGroupsListQuery = {
  __typename?: 'Query';
  getManageEmployeeGroupsList: Array<{ __typename?: 'ManageGroupsItemGroupModel'; id: string; name: string }>;
};

export type ManageClientsListQueryVariables = Exact<{
  input: ClientListParamsInput;
}>;

export type ManageClientsListQuery = {
  __typename?: 'Query';
  manageClientsList: {
    __typename?: 'ManageClientsListModel';
    total: number;
    items: Array<{
      __typename?: 'ManageClientsListItemModel';
      id: string;
      name: string;
      visitedAt: any;
      group: string;
      car?: string | null;
    }>;
  };
};

export type ManageClientsFullItemQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type ManageClientsFullItemQuery = {
  __typename?: 'Query';
  manageClientsFullItem: {
    __typename?: 'ManageClientsFullItemModel';
    id: number;
    name: string;
    phone?: string | null;
    appointment: boolean;
    visitedAt: any;
    car?: string | null;
    accompanying?: { __typename?: 'ManageClientsFullItemAccompanyingModel'; name: string; phone?: string | null } | null;
    interactions: Array<{
      __typename?: 'ManageClientsFullItemInteractionModel';
      type: DealInteractionType;
      status: DealInteractionStatus;
      employee?: string | null;
      group: string;
      clientScore: number;
    }>;
    meta: Array<{ __typename?: 'ManageClientsFullItemMetaModel'; key: DealMeta; value: string }>;
  };
};

export type GetSelfServiceInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelfServiceInfoQuery = {
  __typename?: 'Query';
  getSelfServiceInfo: Array<{ __typename?: 'ManageCompaniesSelfServiceInfoItemModel'; name: string; url: string }>;
};

export type ManageDirectionsQueryVariables = Exact<{
  input: ManageDirectionsListInput;
}>;

export type ManageDirectionsQuery = {
  __typename?: 'Query';
  manageDirectionsList: {
    __typename?: 'ManageDirectionsListModel';
    total: number;
    items: Array<{
      __typename?: 'ManageDirectionsItemModel';
      id: string;
      name: string;
      type: DirectionType;
      group: { __typename?: 'BasicGroupModel'; id: number; name: string };
      user?: { __typename?: 'BasicUserModel'; id: number; name: string } | null;
    }>;
  };
};

export type GetManageUsersToAssignToDirectionQueryVariables = Exact<{
  groupId: Scalars['Int']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetManageUsersToAssignToDirectionQuery = {
  __typename?: 'Query';
  getManageUsersToAssignToDirection: Array<{
    __typename?: 'BasicUserModel';
    id: number;
    name: string;
    groupId: number;
    group: { __typename?: 'BasicGroupModel'; id: number; name: string; companyId: number };
  }>;
};

export type GetManageGroupsToAssignToDirectionQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetManageGroupsToAssignToDirectionQuery = {
  __typename?: 'Query';
  getManageGroupsToAssignToDirection: Array<{ __typename?: 'BasicGroupModel'; id: number; name: string }>;
};

export type ManageCallbackHistoryQueryVariables = Exact<{
  input: ManageCallbackHistoryParamsInput;
}>;

export type ManageCallbackHistoryQuery = {
  __typename?: 'Query';
  manageCallbackHistory: {
    __typename?: 'ManageCallbackHistoryModel';
    total: number;
    items: Array<{
      __typename?: 'ManageCallbackHistoryItemModel';
      id: string;
      createdAt: any;
      eventType: string;
      body: string;
      responseStatus: number;
      responseBody?: string | null;
      error?: string | null;
    }>;
  };
};

export type ManageCallbackQueryVariables = Exact<{ [key: string]: never }>;

export type ManageCallbackQuery = {
  __typename?: 'Query';
  manageCallback: { __typename?: 'ManageCallbackModel'; url: string; key: string; enabled: boolean };
};

export type ManageCompaniesGroupsListQueryVariables = Exact<{
  input: ManageCompaniesGroupsListInput;
}>;

export type ManageCompaniesGroupsListQuery = {
  __typename?: 'Query';
  manageCompaniesGroupsList: {
    __typename?: 'ManageCompaniesGroupsListModel';
    total: number;
    items: Array<{
      __typename?: 'ManageCompaniesGroupsItemModel';
      id: string;
      name?: string | null;
      frozenAt?: any | null;
      frozenReason?: string | null;
      users: number;
      companies: number;
      clients: number;
      techAdminName: string;
      techAdminEmail: string;
      techAdminPhone: string;
    }>;
  };
};

export type ManageCompanyCarsQueryVariables = Exact<{
  input: ManageCarsListInput;
}>;

export type ManageCompanyCarsQuery = {
  __typename?: 'Query';
  manageGetCars: {
    __typename?: 'ManageCarsListModel';
    total: number;
    items: Array<{
      __typename?: 'ManageCarsItemModel';
      id: string;
      brand?: string | null;
      model?: string | null;
      companyId: number;
      carComplectationId?: number | null;
      carComplectation?: {
        __typename?: 'CarLibraryItemModel';
        id: number;
        name: string;
        modification: {
          __typename?: 'CarLibraryItemModificationModel';
          id: number;
          name: string;
          yearFrom: number;
          yearTo: number;
          fuelType: string;
          driveType: string;
          transmission: string;
          power: number;
          engineSize: number;
          bodyType: string;
          doors: number;
          generation: {
            __typename?: 'CarLibraryItemGenerationModel';
            name: string;
            model: {
              __typename?: 'CarLibraryItemModelModel';
              name: string;
              make: { __typename?: 'CarLibraryItemMakeModel'; name: string };
            };
          };
        };
      } | null;
    }>;
  };
};

export type ManageGetCarsLibraryQueryVariables = Exact<{
  input: ListCarsLibraryInput;
}>;

export type ManageGetCarsLibraryQuery = {
  __typename?: 'Query';
  manageGetCarsLibrary: {
    __typename?: 'CarLibraryListModel';
    total: number;
    items: Array<{
      __typename?: 'CarLibraryItemModel';
      id: number;
      name: string;
      modification: {
        __typename?: 'CarLibraryItemModificationModel';
        id: number;
        name: string;
        yearFrom: number;
        yearTo: number;
        fuelType: string;
        driveType: string;
        transmission: string;
        power: number;
        engineSize: number;
        bodyType: string;
        doors: number;
        generation: {
          __typename?: 'CarLibraryItemGenerationModel';
          name: string;
          model: { __typename?: 'CarLibraryItemModelModel'; name: string; make: { __typename?: 'CarLibraryItemMakeModel'; name: string } };
        };
      };
    }>;
  };
};

export type ManageClientsDownloadLinkQueryVariables = Exact<{ [key: string]: never }>;

export type ManageClientsDownloadLinkQuery = { __typename?: 'Query'; manageClientsDownloadLink: string };

export const LoginEmailOrPhoneDocument = gql`
  mutation loginEmailOrPhone($emailOrPhone: String!, $password: String!) {
    loginEmailOrPhone(input: { emailOrPhone: $emailOrPhone, password: $password }) {
      id
      name
      permissions
      role
      group
      company
      subscriptions
    }
  }
`;
export type LoginEmailOrPhoneMutationFn = Apollo.MutationFunction<LoginEmailOrPhoneMutation, LoginEmailOrPhoneMutationVariables>;

/**
 * __useLoginEmailOrPhoneMutation__
 *
 * To run a mutation, you first call `useLoginEmailOrPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailOrPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailOrPhoneMutation, { data, loading, error }] = useLoginEmailOrPhoneMutation({
 *   variables: {
 *      emailOrPhone: // value for 'emailOrPhone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailOrPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginEmailOrPhoneMutation, LoginEmailOrPhoneMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginEmailOrPhoneMutation, LoginEmailOrPhoneMutationVariables>(LoginEmailOrPhoneDocument, options);
}
export type LoginEmailOrPhoneMutationHookResult = ReturnType<typeof useLoginEmailOrPhoneMutation>;
export type LoginEmailOrPhoneMutationResult = Apollo.MutationResult<LoginEmailOrPhoneMutation>;
export type LoginEmailOrPhoneMutationOptions = Apollo.BaseMutationOptions<LoginEmailOrPhoneMutation, LoginEmailOrPhoneMutationVariables>;
export const RequestResetPasswordDocument = gql`
  mutation requestResetPassword($email: String!, $authContext: AuthContextEnum!) {
    requestResetPassword(email: $email, authContext: $authContext)
  }
`;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      authContext: // value for 'authContext'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
}
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: PasswordResetInput!) {
    resetPassword(input: $input)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RegisterDocument = gql`
  mutation register($input: RegistrationInput!) {
    register(input: $input)
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ResendPhoneConfirmationCodeDocument = gql`
  mutation resendPhoneConfirmationCode {
    resendPhoneConfirmationCode
  }
`;
export type ResendPhoneConfirmationCodeMutationFn = Apollo.MutationFunction<
  ResendPhoneConfirmationCodeMutation,
  ResendPhoneConfirmationCodeMutationVariables
>;

/**
 * __useResendPhoneConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useResendPhoneConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendPhoneConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendPhoneConfirmationCodeMutation, { data, loading, error }] = useResendPhoneConfirmationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendPhoneConfirmationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendPhoneConfirmationCodeMutation, ResendPhoneConfirmationCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendPhoneConfirmationCodeMutation, ResendPhoneConfirmationCodeMutationVariables>(
    ResendPhoneConfirmationCodeDocument,
    options
  );
}
export type ResendPhoneConfirmationCodeMutationHookResult = ReturnType<typeof useResendPhoneConfirmationCodeMutation>;
export type ResendPhoneConfirmationCodeMutationResult = Apollo.MutationResult<ResendPhoneConfirmationCodeMutation>;
export type ResendPhoneConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<
  ResendPhoneConfirmationCodeMutation,
  ResendPhoneConfirmationCodeMutationVariables
>;
export const VerifyPhoneDocument = gql`
  mutation verifyPhone($code: String!) {
    verifyPhone(code: $code)
  }
`;
export type VerifyPhoneMutationFn = Apollo.MutationFunction<VerifyPhoneMutation, VerifyPhoneMutationVariables>;

/**
 * __useVerifyPhoneMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneMutation, { data, loading, error }] = useVerifyPhoneMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyPhoneMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyPhoneMutation, VerifyPhoneMutationVariables>(VerifyPhoneDocument, options);
}
export type VerifyPhoneMutationHookResult = ReturnType<typeof useVerifyPhoneMutation>;
export type VerifyPhoneMutationResult = Apollo.MutationResult<VerifyPhoneMutation>;
export type VerifyPhoneMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const FinalizeRegistrationDocument = gql`
  mutation finalizeRegistration($password: String!) {
    finalizeRegistration(password: $password) {
      user {
        id
        name
        permissions
        role
        group
        company
      }
    }
  }
`;
export type FinalizeRegistrationMutationFn = Apollo.MutationFunction<FinalizeRegistrationMutation, FinalizeRegistrationMutationVariables>;

/**
 * __useFinalizeRegistrationMutation__
 *
 * To run a mutation, you first call `useFinalizeRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeRegistrationMutation, { data, loading, error }] = useFinalizeRegistrationMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useFinalizeRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<FinalizeRegistrationMutation, FinalizeRegistrationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeRegistrationMutation, FinalizeRegistrationMutationVariables>(FinalizeRegistrationDocument, options);
}
export type FinalizeRegistrationMutationHookResult = ReturnType<typeof useFinalizeRegistrationMutation>;
export type FinalizeRegistrationMutationResult = Apollo.MutationResult<FinalizeRegistrationMutation>;
export type FinalizeRegistrationMutationOptions = Apollo.BaseMutationOptions<
  FinalizeRegistrationMutation,
  FinalizeRegistrationMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CreateRoleDocument = gql`
  mutation createRole($input: EditRoleInput!) {
    createRole(input: $input) {
      id
      name
      permissions {
        id
        name
      }
      usersCount
    }
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
  mutation updateRole($id: Int!, $input: EditRoleInput!) {
    updateRole(id: $id, input: $input) {
      id
      name
      permissions {
        id
        name
      }
      usersCount
    }
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($id: Int!) {
    deleteRole(id: $id)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CreateCompanyDocument = gql`
  mutation createCompany($input: ManageCompaniesEditItemInput!) {
    createCompany(input: $input) {
      id
      name
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
  mutation updateCompany($id: Int!, $input: ManageCompaniesEditItemInput!) {
    updateCompany(id: $id, input: $input) {
      id
      name
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
  mutation deleteCompany($id: Int!) {
    deleteCompany(id: $id)
  }
`;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
}
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const CreateUsersGroupDocument = gql`
  mutation createUsersGroup($input: ManageGroupsEditItemInput!) {
    createUsersGroup(input: $input) {
      id
      name
      usersCount
    }
  }
`;
export type CreateUsersGroupMutationFn = Apollo.MutationFunction<CreateUsersGroupMutation, CreateUsersGroupMutationVariables>;

/**
 * __useCreateUsersGroupMutation__
 *
 * To run a mutation, you first call `useCreateUsersGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersGroupMutation, { data, loading, error }] = useCreateUsersGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUsersGroupMutation, CreateUsersGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUsersGroupMutation, CreateUsersGroupMutationVariables>(CreateUsersGroupDocument, options);
}
export type CreateUsersGroupMutationHookResult = ReturnType<typeof useCreateUsersGroupMutation>;
export type CreateUsersGroupMutationResult = Apollo.MutationResult<CreateUsersGroupMutation>;
export type CreateUsersGroupMutationOptions = Apollo.BaseMutationOptions<CreateUsersGroupMutation, CreateUsersGroupMutationVariables>;
export const UpdateUsersGroupDocument = gql`
  mutation updateUsersGroup($id: Int!, $input: ManageGroupsEditItemInput!) {
    updateUsersGroup(id: $id, input: $input) {
      id
      name
      usersCount
    }
  }
`;
export type UpdateUsersGroupMutationFn = Apollo.MutationFunction<UpdateUsersGroupMutation, UpdateUsersGroupMutationVariables>;

/**
 * __useUpdateUsersGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUsersGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersGroupMutation, { data, loading, error }] = useUpdateUsersGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUsersGroupMutation, UpdateUsersGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUsersGroupMutation, UpdateUsersGroupMutationVariables>(UpdateUsersGroupDocument, options);
}
export type UpdateUsersGroupMutationHookResult = ReturnType<typeof useUpdateUsersGroupMutation>;
export type UpdateUsersGroupMutationResult = Apollo.MutationResult<UpdateUsersGroupMutation>;
export type UpdateUsersGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUsersGroupMutation, UpdateUsersGroupMutationVariables>;
export const DeleteUsersGroupDocument = gql`
  mutation deleteUsersGroup($id: Int!) {
    deleteUsersGroup(id: $id)
  }
`;
export type DeleteUsersGroupMutationFn = Apollo.MutationFunction<DeleteUsersGroupMutation, DeleteUsersGroupMutationVariables>;

/**
 * __useDeleteUsersGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUsersGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersGroupMutation, { data, loading, error }] = useDeleteUsersGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUsersGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUsersGroupMutation, DeleteUsersGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUsersGroupMutation, DeleteUsersGroupMutationVariables>(DeleteUsersGroupDocument, options);
}
export type DeleteUsersGroupMutationHookResult = ReturnType<typeof useDeleteUsersGroupMutation>;
export type DeleteUsersGroupMutationResult = Apollo.MutationResult<DeleteUsersGroupMutation>;
export type DeleteUsersGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUsersGroupMutation, DeleteUsersGroupMutationVariables>;
export const ManageEmployeeInviteDocument = gql`
  mutation manageEmployeeInvite($input: ManageEmployeeInviteInput!) {
    manageEmployeeInvite(input: $input)
  }
`;
export type ManageEmployeeInviteMutationFn = Apollo.MutationFunction<ManageEmployeeInviteMutation, ManageEmployeeInviteMutationVariables>;

/**
 * __useManageEmployeeInviteMutation__
 *
 * To run a mutation, you first call `useManageEmployeeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageEmployeeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageEmployeeInviteMutation, { data, loading, error }] = useManageEmployeeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageEmployeeInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageEmployeeInviteMutation, ManageEmployeeInviteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageEmployeeInviteMutation, ManageEmployeeInviteMutationVariables>(ManageEmployeeInviteDocument, options);
}
export type ManageEmployeeInviteMutationHookResult = ReturnType<typeof useManageEmployeeInviteMutation>;
export type ManageEmployeeInviteMutationResult = Apollo.MutationResult<ManageEmployeeInviteMutation>;
export type ManageEmployeeInviteMutationOptions = Apollo.BaseMutationOptions<
  ManageEmployeeInviteMutation,
  ManageEmployeeInviteMutationVariables
>;
export const ManageEmployeeUpdateDocument = gql`
  mutation manageEmployeeUpdate($id: Int!, $input: ManageEmployeeEditItemInput!) {
    manageEmployeeUpdate(id: $id, input: $input)
  }
`;
export type ManageEmployeeUpdateMutationFn = Apollo.MutationFunction<ManageEmployeeUpdateMutation, ManageEmployeeUpdateMutationVariables>;

/**
 * __useManageEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useManageEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageEmployeeUpdateMutation, { data, loading, error }] = useManageEmployeeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageEmployeeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageEmployeeUpdateMutation, ManageEmployeeUpdateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageEmployeeUpdateMutation, ManageEmployeeUpdateMutationVariables>(ManageEmployeeUpdateDocument, options);
}
export type ManageEmployeeUpdateMutationHookResult = ReturnType<typeof useManageEmployeeUpdateMutation>;
export type ManageEmployeeUpdateMutationResult = Apollo.MutationResult<ManageEmployeeUpdateMutation>;
export type ManageEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  ManageEmployeeUpdateMutation,
  ManageEmployeeUpdateMutationVariables
>;
export const ManageEmployeeDeleteDocument = gql`
  mutation manageEmployeeDelete($id: Int!) {
    manageEmployeeDelete(id: $id)
  }
`;
export type ManageEmployeeDeleteMutationFn = Apollo.MutationFunction<ManageEmployeeDeleteMutation, ManageEmployeeDeleteMutationVariables>;

/**
 * __useManageEmployeeDeleteMutation__
 *
 * To run a mutation, you first call `useManageEmployeeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageEmployeeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageEmployeeDeleteMutation, { data, loading, error }] = useManageEmployeeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageEmployeeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageEmployeeDeleteMutation, ManageEmployeeDeleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageEmployeeDeleteMutation, ManageEmployeeDeleteMutationVariables>(ManageEmployeeDeleteDocument, options);
}
export type ManageEmployeeDeleteMutationHookResult = ReturnType<typeof useManageEmployeeDeleteMutation>;
export type ManageEmployeeDeleteMutationResult = Apollo.MutationResult<ManageEmployeeDeleteMutation>;
export type ManageEmployeeDeleteMutationOptions = Apollo.BaseMutationOptions<
  ManageEmployeeDeleteMutation,
  ManageEmployeeDeleteMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: ProfileUpdateInput!) {
    updateProfile(input: $input)
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const ChangePasswordDocument = gql`
  mutation changePassword($input: PasswordChangeInput!) {
    changePassword(input: $input)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateDirectionDocument = gql`
  mutation createDirection($input: ManageDirectionsEditItemInput!) {
    createDirection(input: $input) {
      id
      name
      type
      group {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;
export type CreateDirectionMutationFn = Apollo.MutationFunction<CreateDirectionMutation, CreateDirectionMutationVariables>;

/**
 * __useCreateDirectionMutation__
 *
 * To run a mutation, you first call `useCreateDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectionMutation, { data, loading, error }] = useCreateDirectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDirectionMutation, CreateDirectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDirectionMutation, CreateDirectionMutationVariables>(CreateDirectionDocument, options);
}
export type CreateDirectionMutationHookResult = ReturnType<typeof useCreateDirectionMutation>;
export type CreateDirectionMutationResult = Apollo.MutationResult<CreateDirectionMutation>;
export type CreateDirectionMutationOptions = Apollo.BaseMutationOptions<CreateDirectionMutation, CreateDirectionMutationVariables>;
export const UpdateDirectionDocument = gql`
  mutation updateDirection($id: Int!, $input: ManageDirectionsEditItemInput!) {
    updateDirection(id: $id, input: $input) {
      id
      name
      type
      group {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;
export type UpdateDirectionMutationFn = Apollo.MutationFunction<UpdateDirectionMutation, UpdateDirectionMutationVariables>;

/**
 * __useUpdateDirectionMutation__
 *
 * To run a mutation, you first call `useUpdateDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectionMutation, { data, loading, error }] = useUpdateDirectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDirectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDirectionMutation, UpdateDirectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDirectionMutation, UpdateDirectionMutationVariables>(UpdateDirectionDocument, options);
}
export type UpdateDirectionMutationHookResult = ReturnType<typeof useUpdateDirectionMutation>;
export type UpdateDirectionMutationResult = Apollo.MutationResult<UpdateDirectionMutation>;
export type UpdateDirectionMutationOptions = Apollo.BaseMutationOptions<UpdateDirectionMutation, UpdateDirectionMutationVariables>;
export const DeleteDirectionDocument = gql`
  mutation deleteDirection($id: Int!) {
    deleteDirection(id: $id)
  }
`;
export type DeleteDirectionMutationFn = Apollo.MutationFunction<DeleteDirectionMutation, DeleteDirectionMutationVariables>;

/**
 * __useDeleteDirectionMutation__
 *
 * To run a mutation, you first call `useDeleteDirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirectionMutation, { data, loading, error }] = useDeleteDirectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDirectionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDirectionMutation, DeleteDirectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDirectionMutation, DeleteDirectionMutationVariables>(DeleteDirectionDocument, options);
}
export type DeleteDirectionMutationHookResult = ReturnType<typeof useDeleteDirectionMutation>;
export type DeleteDirectionMutationResult = Apollo.MutationResult<DeleteDirectionMutation>;
export type DeleteDirectionMutationOptions = Apollo.BaseMutationOptions<DeleteDirectionMutation, DeleteDirectionMutationVariables>;
export const ManageUpdateCallbackDocument = gql`
  mutation manageUpdateCallback($input: ManageUpdateCallbackInput!) {
    manageUpdateCallback(input: $input) {
      url
      key
      enabled
    }
  }
`;
export type ManageUpdateCallbackMutationFn = Apollo.MutationFunction<ManageUpdateCallbackMutation, ManageUpdateCallbackMutationVariables>;

/**
 * __useManageUpdateCallbackMutation__
 *
 * To run a mutation, you first call `useManageUpdateCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUpdateCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUpdateCallbackMutation, { data, loading, error }] = useManageUpdateCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageUpdateCallbackMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageUpdateCallbackMutation, ManageUpdateCallbackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageUpdateCallbackMutation, ManageUpdateCallbackMutationVariables>(ManageUpdateCallbackDocument, options);
}
export type ManageUpdateCallbackMutationHookResult = ReturnType<typeof useManageUpdateCallbackMutation>;
export type ManageUpdateCallbackMutationResult = Apollo.MutationResult<ManageUpdateCallbackMutation>;
export type ManageUpdateCallbackMutationOptions = Apollo.BaseMutationOptions<
  ManageUpdateCallbackMutation,
  ManageUpdateCallbackMutationVariables
>;
export const ManageUpdateCompaniesGroupDocument = gql`
  mutation manageUpdateCompaniesGroup($id: Int!, $input: ManageCompaniesGroupsEditItemInput!) {
    manageUpdateCompaniesGroup(id: $id, input: $input) {
      id
      name
      frozenAt
      frozenReason
      users
      companies
      clients
      techAdminName
      techAdminEmail
      techAdminPhone
    }
  }
`;
export type ManageUpdateCompaniesGroupMutationFn = Apollo.MutationFunction<
  ManageUpdateCompaniesGroupMutation,
  ManageUpdateCompaniesGroupMutationVariables
>;

/**
 * __useManageUpdateCompaniesGroupMutation__
 *
 * To run a mutation, you first call `useManageUpdateCompaniesGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUpdateCompaniesGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUpdateCompaniesGroupMutation, { data, loading, error }] = useManageUpdateCompaniesGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageUpdateCompaniesGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageUpdateCompaniesGroupMutation, ManageUpdateCompaniesGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageUpdateCompaniesGroupMutation, ManageUpdateCompaniesGroupMutationVariables>(
    ManageUpdateCompaniesGroupDocument,
    options
  );
}
export type ManageUpdateCompaniesGroupMutationHookResult = ReturnType<typeof useManageUpdateCompaniesGroupMutation>;
export type ManageUpdateCompaniesGroupMutationResult = Apollo.MutationResult<ManageUpdateCompaniesGroupMutation>;
export type ManageUpdateCompaniesGroupMutationOptions = Apollo.BaseMutationOptions<
  ManageUpdateCompaniesGroupMutation,
  ManageUpdateCompaniesGroupMutationVariables
>;
export const ManageFreezeCompaniesGroupDocument = gql`
  mutation manageFreezeCompaniesGroup($id: Int!, $reason: String!) {
    manageFreezeCompaniesGroup(id: $id, reason: $reason)
  }
`;
export type ManageFreezeCompaniesGroupMutationFn = Apollo.MutationFunction<
  ManageFreezeCompaniesGroupMutation,
  ManageFreezeCompaniesGroupMutationVariables
>;

/**
 * __useManageFreezeCompaniesGroupMutation__
 *
 * To run a mutation, you first call `useManageFreezeCompaniesGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageFreezeCompaniesGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageFreezeCompaniesGroupMutation, { data, loading, error }] = useManageFreezeCompaniesGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useManageFreezeCompaniesGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageFreezeCompaniesGroupMutation, ManageFreezeCompaniesGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageFreezeCompaniesGroupMutation, ManageFreezeCompaniesGroupMutationVariables>(
    ManageFreezeCompaniesGroupDocument,
    options
  );
}
export type ManageFreezeCompaniesGroupMutationHookResult = ReturnType<typeof useManageFreezeCompaniesGroupMutation>;
export type ManageFreezeCompaniesGroupMutationResult = Apollo.MutationResult<ManageFreezeCompaniesGroupMutation>;
export type ManageFreezeCompaniesGroupMutationOptions = Apollo.BaseMutationOptions<
  ManageFreezeCompaniesGroupMutation,
  ManageFreezeCompaniesGroupMutationVariables
>;
export const ManageUnfreezeCompaniesGroupDocument = gql`
  mutation manageUnfreezeCompaniesGroup($id: Int!) {
    manageUnfreezeCompaniesGroup(id: $id)
  }
`;
export type ManageUnfreezeCompaniesGroupMutationFn = Apollo.MutationFunction<
  ManageUnfreezeCompaniesGroupMutation,
  ManageUnfreezeCompaniesGroupMutationVariables
>;

/**
 * __useManageUnfreezeCompaniesGroupMutation__
 *
 * To run a mutation, you first call `useManageUnfreezeCompaniesGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUnfreezeCompaniesGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUnfreezeCompaniesGroupMutation, { data, loading, error }] = useManageUnfreezeCompaniesGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageUnfreezeCompaniesGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageUnfreezeCompaniesGroupMutation, ManageUnfreezeCompaniesGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageUnfreezeCompaniesGroupMutation, ManageUnfreezeCompaniesGroupMutationVariables>(
    ManageUnfreezeCompaniesGroupDocument,
    options
  );
}
export type ManageUnfreezeCompaniesGroupMutationHookResult = ReturnType<typeof useManageUnfreezeCompaniesGroupMutation>;
export type ManageUnfreezeCompaniesGroupMutationResult = Apollo.MutationResult<ManageUnfreezeCompaniesGroupMutation>;
export type ManageUnfreezeCompaniesGroupMutationOptions = Apollo.BaseMutationOptions<
  ManageUnfreezeCompaniesGroupMutation,
  ManageUnfreezeCompaniesGroupMutationVariables
>;
export const ManageDeleteCompaniesGroupDocument = gql`
  mutation manageDeleteCompaniesGroup($id: Int!) {
    manageDeleteCompaniesGroup(id: $id)
  }
`;
export type ManageDeleteCompaniesGroupMutationFn = Apollo.MutationFunction<
  ManageDeleteCompaniesGroupMutation,
  ManageDeleteCompaniesGroupMutationVariables
>;

/**
 * __useManageDeleteCompaniesGroupMutation__
 *
 * To run a mutation, you first call `useManageDeleteCompaniesGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageDeleteCompaniesGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageDeleteCompaniesGroupMutation, { data, loading, error }] = useManageDeleteCompaniesGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageDeleteCompaniesGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageDeleteCompaniesGroupMutation, ManageDeleteCompaniesGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageDeleteCompaniesGroupMutation, ManageDeleteCompaniesGroupMutationVariables>(
    ManageDeleteCompaniesGroupDocument,
    options
  );
}
export type ManageDeleteCompaniesGroupMutationHookResult = ReturnType<typeof useManageDeleteCompaniesGroupMutation>;
export type ManageDeleteCompaniesGroupMutationResult = Apollo.MutationResult<ManageDeleteCompaniesGroupMutation>;
export type ManageDeleteCompaniesGroupMutationOptions = Apollo.BaseMutationOptions<
  ManageDeleteCompaniesGroupMutation,
  ManageDeleteCompaniesGroupMutationVariables
>;
export const CreateCarDocument = gql`
  mutation createCar($input: ManageCarsEditItemInput!) {
    manageCreateCar(input: $input) {
      id
      brand
      model
      companyId
      carComplectationId
      carComplectation {
        id
        name
        modification {
          id
          name
          yearFrom
          yearTo
          fuelType
          driveType
          transmission
          power
          engineSize
          bodyType
          doors
          generation {
            name
            model {
              name
              make {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateCarMutationFn = Apollo.MutationFunction<CreateCarMutation, CreateCarMutationVariables>;

/**
 * __useCreateCarMutation__
 *
 * To run a mutation, you first call `useCreateCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarMutation, { data, loading, error }] = useCreateCarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarMutation, CreateCarMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCarMutation, CreateCarMutationVariables>(CreateCarDocument, options);
}
export type CreateCarMutationHookResult = ReturnType<typeof useCreateCarMutation>;
export type CreateCarMutationResult = Apollo.MutationResult<CreateCarMutation>;
export type CreateCarMutationOptions = Apollo.BaseMutationOptions<CreateCarMutation, CreateCarMutationVariables>;
export const UpdateCarDocument = gql`
  mutation updateCar($id: Int!, $input: ManageCarsEditItemInput!) {
    manageUpdateCar(id: $id, input: $input) {
      id
      brand
      model
      companyId
      carComplectationId
      carComplectation {
        id
        name
        modification {
          id
          name
          yearFrom
          yearTo
          fuelType
          driveType
          transmission
          power
          engineSize
          bodyType
          doors
          generation {
            name
            model {
              name
              make {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type UpdateCarMutationFn = Apollo.MutationFunction<UpdateCarMutation, UpdateCarMutationVariables>;

/**
 * __useUpdateCarMutation__
 *
 * To run a mutation, you first call `useUpdateCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarMutation, { data, loading, error }] = useUpdateCarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCarMutation, UpdateCarMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCarMutation, UpdateCarMutationVariables>(UpdateCarDocument, options);
}
export type UpdateCarMutationHookResult = ReturnType<typeof useUpdateCarMutation>;
export type UpdateCarMutationResult = Apollo.MutationResult<UpdateCarMutation>;
export type UpdateCarMutationOptions = Apollo.BaseMutationOptions<UpdateCarMutation, UpdateCarMutationVariables>;
export const DeleteCarDocument = gql`
  mutation deleteCar($id: Int!) {
    manageDeleteCar(id: $id)
  }
`;
export type DeleteCarMutationFn = Apollo.MutationFunction<DeleteCarMutation, DeleteCarMutationVariables>;

/**
 * __useDeleteCarMutation__
 *
 * To run a mutation, you first call `useDeleteCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarMutation, { data, loading, error }] = useDeleteCarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCarMutation, DeleteCarMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCarMutation, DeleteCarMutationVariables>(DeleteCarDocument, options);
}
export type DeleteCarMutationHookResult = ReturnType<typeof useDeleteCarMutation>;
export type DeleteCarMutationResult = Apollo.MutationResult<DeleteCarMutation>;
export type DeleteCarMutationOptions = Apollo.BaseMutationOptions<DeleteCarMutation, DeleteCarMutationVariables>;
export const ManageClientsArchiveDealDocument = gql`
  mutation manageClientsArchiveDeal($id: Int!) {
    manageClientsArchiveDeal(id: $id)
  }
`;
export type ManageClientsArchiveDealMutationFn = Apollo.MutationFunction<
  ManageClientsArchiveDealMutation,
  ManageClientsArchiveDealMutationVariables
>;

/**
 * __useManageClientsArchiveDealMutation__
 *
 * To run a mutation, you first call `useManageClientsArchiveDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageClientsArchiveDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageClientsArchiveDealMutation, { data, loading, error }] = useManageClientsArchiveDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageClientsArchiveDealMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageClientsArchiveDealMutation, ManageClientsArchiveDealMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageClientsArchiveDealMutation, ManageClientsArchiveDealMutationVariables>(
    ManageClientsArchiveDealDocument,
    options
  );
}
export type ManageClientsArchiveDealMutationHookResult = ReturnType<typeof useManageClientsArchiveDealMutation>;
export type ManageClientsArchiveDealMutationResult = Apollo.MutationResult<ManageClientsArchiveDealMutation>;
export type ManageClientsArchiveDealMutationOptions = Apollo.BaseMutationOptions<
  ManageClientsArchiveDealMutation,
  ManageClientsArchiveDealMutationVariables
>;
export const ManageClientsUnarchiveDealDocument = gql`
  mutation manageClientsUnarchiveDeal($id: Int!) {
    manageClientsUnarchiveDeal(id: $id)
  }
`;
export type ManageClientsUnarchiveDealMutationFn = Apollo.MutationFunction<
  ManageClientsUnarchiveDealMutation,
  ManageClientsUnarchiveDealMutationVariables
>;

/**
 * __useManageClientsUnarchiveDealMutation__
 *
 * To run a mutation, you first call `useManageClientsUnarchiveDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageClientsUnarchiveDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageClientsUnarchiveDealMutation, { data, loading, error }] = useManageClientsUnarchiveDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageClientsUnarchiveDealMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageClientsUnarchiveDealMutation, ManageClientsUnarchiveDealMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageClientsUnarchiveDealMutation, ManageClientsUnarchiveDealMutationVariables>(
    ManageClientsUnarchiveDealDocument,
    options
  );
}
export type ManageClientsUnarchiveDealMutationHookResult = ReturnType<typeof useManageClientsUnarchiveDealMutation>;
export type ManageClientsUnarchiveDealMutationResult = Apollo.MutationResult<ManageClientsUnarchiveDealMutation>;
export type ManageClientsUnarchiveDealMutationOptions = Apollo.BaseMutationOptions<
  ManageClientsUnarchiveDealMutation,
  ManageClientsUnarchiveDealMutationVariables
>;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      name
      email
      phone
      image
      permissions
      role
      group
      company
      subscriptions
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const RolesDocument = gql`
  query roles($input: RolesParamsInput!) {
    getRoles(input: $input) {
      total
      items {
        id
        name
        permissions {
          id
          name
        }
        usersCount
      }
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRolesQuery(baseOptions: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
}
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const RoleFullDocument = gql`
  query roleFull($id: Int!) {
    getRole(id: $id) {
      id
      name
      permissions {
        id
        name
      }
      users {
        id
        name
      }
    }
  }
`;

/**
 * __useRoleFullQuery__
 *
 * To run a query within a React component, call `useRoleFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleFullQuery(baseOptions: Apollo.QueryHookOptions<RoleFullQuery, RoleFullQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleFullQuery, RoleFullQueryVariables>(RoleFullDocument, options);
}
export function useRoleFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleFullQuery, RoleFullQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleFullQuery, RoleFullQueryVariables>(RoleFullDocument, options);
}
export type RoleFullQueryHookResult = ReturnType<typeof useRoleFullQuery>;
export type RoleFullLazyQueryHookResult = ReturnType<typeof useRoleFullLazyQuery>;
export type RoleFullQueryResult = Apollo.QueryResult<RoleFullQuery, RoleFullQueryVariables>;
export const AllPermissionsDocument = gql`
  query allPermissions {
    allPermissions {
      id
      name
    }
  }
`;

/**
 * __useAllPermissionsQuery__
 *
 * To run a query within a React component, call `useAllPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<AllPermissionsQuery, AllPermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPermissionsQuery, AllPermissionsQueryVariables>(AllPermissionsDocument, options);
}
export function useAllPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPermissionsQuery, AllPermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllPermissionsQuery, AllPermissionsQueryVariables>(AllPermissionsDocument, options);
}
export type AllPermissionsQueryHookResult = ReturnType<typeof useAllPermissionsQuery>;
export type AllPermissionsLazyQueryHookResult = ReturnType<typeof useAllPermissionsLazyQuery>;
export type AllPermissionsQueryResult = Apollo.QueryResult<AllPermissionsQuery, AllPermissionsQueryVariables>;
export const GetUsersToAssignDocument = gql`
  query getUsersToAssign($query: String!) {
    getUsersToAssign(query: $query) {
      id
      name
    }
  }
`;

/**
 * __useGetUsersToAssignQuery__
 *
 * To run a query within a React component, call `useGetUsersToAssignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersToAssignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersToAssignQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetUsersToAssignQuery(baseOptions: Apollo.QueryHookOptions<GetUsersToAssignQuery, GetUsersToAssignQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersToAssignQuery, GetUsersToAssignQueryVariables>(GetUsersToAssignDocument, options);
}
export function useGetUsersToAssignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersToAssignQuery, GetUsersToAssignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersToAssignQuery, GetUsersToAssignQueryVariables>(GetUsersToAssignDocument, options);
}
export type GetUsersToAssignQueryHookResult = ReturnType<typeof useGetUsersToAssignQuery>;
export type GetUsersToAssignLazyQueryHookResult = ReturnType<typeof useGetUsersToAssignLazyQuery>;
export type GetUsersToAssignQueryResult = Apollo.QueryResult<GetUsersToAssignQuery, GetUsersToAssignQueryVariables>;
export const ManageCompaniesDocument = gql`
  query manageCompanies($input: ManageCompaniesListInput!) {
    getCompanies(input: $input) {
      total
      items {
        id
        name
      }
    }
  }
`;

/**
 * __useManageCompaniesQuery__
 *
 * To run a query within a React component, call `useManageCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageCompaniesQuery(baseOptions: Apollo.QueryHookOptions<ManageCompaniesQuery, ManageCompaniesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageCompaniesQuery, ManageCompaniesQueryVariables>(ManageCompaniesDocument, options);
}
export function useManageCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageCompaniesQuery, ManageCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageCompaniesQuery, ManageCompaniesQueryVariables>(ManageCompaniesDocument, options);
}
export type ManageCompaniesQueryHookResult = ReturnType<typeof useManageCompaniesQuery>;
export type ManageCompaniesLazyQueryHookResult = ReturnType<typeof useManageCompaniesLazyQuery>;
export type ManageCompaniesQueryResult = Apollo.QueryResult<ManageCompaniesQuery, ManageCompaniesQueryVariables>;
export const ManageUsersGroupsDocument = gql`
  query manageUsersGroups($input: ManageGroupsListInput!) {
    getUsersGroups(input: $input) {
      total
      items {
        id
        name
        usersCount
      }
    }
  }
`;

/**
 * __useManageUsersGroupsQuery__
 *
 * To run a query within a React component, call `useManageUsersGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageUsersGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageUsersGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageUsersGroupsQuery(baseOptions: Apollo.QueryHookOptions<ManageUsersGroupsQuery, ManageUsersGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageUsersGroupsQuery, ManageUsersGroupsQueryVariables>(ManageUsersGroupsDocument, options);
}
export function useManageUsersGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageUsersGroupsQuery, ManageUsersGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageUsersGroupsQuery, ManageUsersGroupsQueryVariables>(ManageUsersGroupsDocument, options);
}
export type ManageUsersGroupsQueryHookResult = ReturnType<typeof useManageUsersGroupsQuery>;
export type ManageUsersGroupsLazyQueryHookResult = ReturnType<typeof useManageUsersGroupsLazyQuery>;
export type ManageUsersGroupsQueryResult = Apollo.QueryResult<ManageUsersGroupsQuery, ManageUsersGroupsQueryVariables>;
export const ManageUsersGroupFullDocument = gql`
  query manageUsersGroupFull($id: Int!) {
    getUsersGroup(id: $id) {
      id
      name
      companyId
      users {
        id
        name
      }
    }
  }
`;

/**
 * __useManageUsersGroupFullQuery__
 *
 * To run a query within a React component, call `useManageUsersGroupFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageUsersGroupFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageUsersGroupFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageUsersGroupFullQuery(
  baseOptions: Apollo.QueryHookOptions<ManageUsersGroupFullQuery, ManageUsersGroupFullQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageUsersGroupFullQuery, ManageUsersGroupFullQueryVariables>(ManageUsersGroupFullDocument, options);
}
export function useManageUsersGroupFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageUsersGroupFullQuery, ManageUsersGroupFullQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageUsersGroupFullQuery, ManageUsersGroupFullQueryVariables>(ManageUsersGroupFullDocument, options);
}
export type ManageUsersGroupFullQueryHookResult = ReturnType<typeof useManageUsersGroupFullQuery>;
export type ManageUsersGroupFullLazyQueryHookResult = ReturnType<typeof useManageUsersGroupFullLazyQuery>;
export type ManageUsersGroupFullQueryResult = Apollo.QueryResult<ManageUsersGroupFullQuery, ManageUsersGroupFullQueryVariables>;
export const GetManageUsersToAssignToGroupDocument = gql`
  query getManageUsersToAssignToGroup($query: String!) {
    getManageUsersToAssignToGroup(query: $query) {
      id
      name
    }
  }
`;

/**
 * __useGetManageUsersToAssignToGroupQuery__
 *
 * To run a query within a React component, call `useGetManageUsersToAssignToGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageUsersToAssignToGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageUsersToAssignToGroupQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetManageUsersToAssignToGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GetManageUsersToAssignToGroupQuery, GetManageUsersToAssignToGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManageUsersToAssignToGroupQuery, GetManageUsersToAssignToGroupQueryVariables>(
    GetManageUsersToAssignToGroupDocument,
    options
  );
}
export function useGetManageUsersToAssignToGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManageUsersToAssignToGroupQuery, GetManageUsersToAssignToGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManageUsersToAssignToGroupQuery, GetManageUsersToAssignToGroupQueryVariables>(
    GetManageUsersToAssignToGroupDocument,
    options
  );
}
export type GetManageUsersToAssignToGroupQueryHookResult = ReturnType<typeof useGetManageUsersToAssignToGroupQuery>;
export type GetManageUsersToAssignToGroupLazyQueryHookResult = ReturnType<typeof useGetManageUsersToAssignToGroupLazyQuery>;
export type GetManageUsersToAssignToGroupQueryResult = Apollo.QueryResult<
  GetManageUsersToAssignToGroupQuery,
  GetManageUsersToAssignToGroupQueryVariables
>;
export const ManageEmployeeDocument = gql`
  query manageEmployee($input: ManageEmployeeListInput!) {
    getManageEmployee(input: $input) {
      total
      items {
        id
        name
        email
        phone
        status
        createdAt
        role {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useManageEmployeeQuery__
 *
 * To run a query within a React component, call `useManageEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageEmployeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageEmployeeQuery(baseOptions: Apollo.QueryHookOptions<ManageEmployeeQuery, ManageEmployeeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageEmployeeQuery, ManageEmployeeQueryVariables>(ManageEmployeeDocument, options);
}
export function useManageEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageEmployeeQuery, ManageEmployeeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageEmployeeQuery, ManageEmployeeQueryVariables>(ManageEmployeeDocument, options);
}
export type ManageEmployeeQueryHookResult = ReturnType<typeof useManageEmployeeQuery>;
export type ManageEmployeeLazyQueryHookResult = ReturnType<typeof useManageEmployeeLazyQuery>;
export type ManageEmployeeQueryResult = Apollo.QueryResult<ManageEmployeeQuery, ManageEmployeeQueryVariables>;
export const GetManageEmployeeRolesListDocument = gql`
  query getManageEmployeeRolesList($input: ManageEmployeeListRolesInput!) {
    getManageEmployeeRolesList(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetManageEmployeeRolesListQuery__
 *
 * To run a query within a React component, call `useGetManageEmployeeRolesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageEmployeeRolesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageEmployeeRolesListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetManageEmployeeRolesListQuery(
  baseOptions: Apollo.QueryHookOptions<GetManageEmployeeRolesListQuery, GetManageEmployeeRolesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManageEmployeeRolesListQuery, GetManageEmployeeRolesListQueryVariables>(
    GetManageEmployeeRolesListDocument,
    options
  );
}
export function useGetManageEmployeeRolesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManageEmployeeRolesListQuery, GetManageEmployeeRolesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManageEmployeeRolesListQuery, GetManageEmployeeRolesListQueryVariables>(
    GetManageEmployeeRolesListDocument,
    options
  );
}
export type GetManageEmployeeRolesListQueryHookResult = ReturnType<typeof useGetManageEmployeeRolesListQuery>;
export type GetManageEmployeeRolesListLazyQueryHookResult = ReturnType<typeof useGetManageEmployeeRolesListLazyQuery>;
export type GetManageEmployeeRolesListQueryResult = Apollo.QueryResult<
  GetManageEmployeeRolesListQuery,
  GetManageEmployeeRolesListQueryVariables
>;
export const GetManageEmployeeGroupsListDocument = gql`
  query getManageEmployeeGroupsList($input: ManageEmployeeListGroupsInput!) {
    getManageEmployeeGroupsList(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetManageEmployeeGroupsListQuery__
 *
 * To run a query within a React component, call `useGetManageEmployeeGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageEmployeeGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageEmployeeGroupsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetManageEmployeeGroupsListQuery(
  baseOptions: Apollo.QueryHookOptions<GetManageEmployeeGroupsListQuery, GetManageEmployeeGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManageEmployeeGroupsListQuery, GetManageEmployeeGroupsListQueryVariables>(
    GetManageEmployeeGroupsListDocument,
    options
  );
}
export function useGetManageEmployeeGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManageEmployeeGroupsListQuery, GetManageEmployeeGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManageEmployeeGroupsListQuery, GetManageEmployeeGroupsListQueryVariables>(
    GetManageEmployeeGroupsListDocument,
    options
  );
}
export type GetManageEmployeeGroupsListQueryHookResult = ReturnType<typeof useGetManageEmployeeGroupsListQuery>;
export type GetManageEmployeeGroupsListLazyQueryHookResult = ReturnType<typeof useGetManageEmployeeGroupsListLazyQuery>;
export type GetManageEmployeeGroupsListQueryResult = Apollo.QueryResult<
  GetManageEmployeeGroupsListQuery,
  GetManageEmployeeGroupsListQueryVariables
>;
export const ManageClientsListDocument = gql`
  query manageClientsList($input: ClientListParamsInput!) {
    manageClientsList(input: $input) {
      total
      items {
        id
        name
        visitedAt
        group
        car
      }
    }
  }
`;

/**
 * __useManageClientsListQuery__
 *
 * To run a query within a React component, call `useManageClientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageClientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageClientsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageClientsListQuery(baseOptions: Apollo.QueryHookOptions<ManageClientsListQuery, ManageClientsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageClientsListQuery, ManageClientsListQueryVariables>(ManageClientsListDocument, options);
}
export function useManageClientsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageClientsListQuery, ManageClientsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageClientsListQuery, ManageClientsListQueryVariables>(ManageClientsListDocument, options);
}
export type ManageClientsListQueryHookResult = ReturnType<typeof useManageClientsListQuery>;
export type ManageClientsListLazyQueryHookResult = ReturnType<typeof useManageClientsListLazyQuery>;
export type ManageClientsListQueryResult = Apollo.QueryResult<ManageClientsListQuery, ManageClientsListQueryVariables>;
export const ManageClientsFullItemDocument = gql`
  query manageClientsFullItem($id: Int!) {
    manageClientsFullItem(id: $id) {
      id
      name
      phone
      appointment
      visitedAt
      accompanying {
        name
        phone
      }
      interactions {
        type
        status
        employee
        group
        clientScore
      }
      meta {
        key
        value
      }
      car
    }
  }
`;

/**
 * __useManageClientsFullItemQuery__
 *
 * To run a query within a React component, call `useManageClientsFullItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageClientsFullItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageClientsFullItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManageClientsFullItemQuery(
  baseOptions: Apollo.QueryHookOptions<ManageClientsFullItemQuery, ManageClientsFullItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageClientsFullItemQuery, ManageClientsFullItemQueryVariables>(ManageClientsFullItemDocument, options);
}
export function useManageClientsFullItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageClientsFullItemQuery, ManageClientsFullItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageClientsFullItemQuery, ManageClientsFullItemQueryVariables>(ManageClientsFullItemDocument, options);
}
export type ManageClientsFullItemQueryHookResult = ReturnType<typeof useManageClientsFullItemQuery>;
export type ManageClientsFullItemLazyQueryHookResult = ReturnType<typeof useManageClientsFullItemLazyQuery>;
export type ManageClientsFullItemQueryResult = Apollo.QueryResult<ManageClientsFullItemQuery, ManageClientsFullItemQueryVariables>;
export const GetSelfServiceInfoDocument = gql`
  query getSelfServiceInfo {
    getSelfServiceInfo {
      name
      url
    }
  }
`;

/**
 * __useGetSelfServiceInfoQuery__
 *
 * To run a query within a React component, call `useGetSelfServiceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfServiceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfServiceInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfServiceInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSelfServiceInfoQuery, GetSelfServiceInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelfServiceInfoQuery, GetSelfServiceInfoQueryVariables>(GetSelfServiceInfoDocument, options);
}
export function useGetSelfServiceInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSelfServiceInfoQuery, GetSelfServiceInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelfServiceInfoQuery, GetSelfServiceInfoQueryVariables>(GetSelfServiceInfoDocument, options);
}
export type GetSelfServiceInfoQueryHookResult = ReturnType<typeof useGetSelfServiceInfoQuery>;
export type GetSelfServiceInfoLazyQueryHookResult = ReturnType<typeof useGetSelfServiceInfoLazyQuery>;
export type GetSelfServiceInfoQueryResult = Apollo.QueryResult<GetSelfServiceInfoQuery, GetSelfServiceInfoQueryVariables>;
export const ManageDirectionsDocument = gql`
  query manageDirections($input: ManageDirectionsListInput!) {
    manageDirectionsList(input: $input) {
      total
      items {
        id
        name
        type
        group {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useManageDirectionsQuery__
 *
 * To run a query within a React component, call `useManageDirectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageDirectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageDirectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageDirectionsQuery(baseOptions: Apollo.QueryHookOptions<ManageDirectionsQuery, ManageDirectionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageDirectionsQuery, ManageDirectionsQueryVariables>(ManageDirectionsDocument, options);
}
export function useManageDirectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageDirectionsQuery, ManageDirectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageDirectionsQuery, ManageDirectionsQueryVariables>(ManageDirectionsDocument, options);
}
export type ManageDirectionsQueryHookResult = ReturnType<typeof useManageDirectionsQuery>;
export type ManageDirectionsLazyQueryHookResult = ReturnType<typeof useManageDirectionsLazyQuery>;
export type ManageDirectionsQueryResult = Apollo.QueryResult<ManageDirectionsQuery, ManageDirectionsQueryVariables>;
export const GetManageUsersToAssignToDirectionDocument = gql`
  query getManageUsersToAssignToDirection($groupId: Int!, $companyId: Int) {
    getManageUsersToAssignToDirection(groupId: $groupId, companyId: $companyId) {
      id
      name
      groupId
      group {
        id
        name
        companyId
      }
    }
  }
`;

/**
 * __useGetManageUsersToAssignToDirectionQuery__
 *
 * To run a query within a React component, call `useGetManageUsersToAssignToDirectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageUsersToAssignToDirectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageUsersToAssignToDirectionQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetManageUsersToAssignToDirectionQuery(
  baseOptions: Apollo.QueryHookOptions<GetManageUsersToAssignToDirectionQuery, GetManageUsersToAssignToDirectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManageUsersToAssignToDirectionQuery, GetManageUsersToAssignToDirectionQueryVariables>(
    GetManageUsersToAssignToDirectionDocument,
    options
  );
}
export function useGetManageUsersToAssignToDirectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManageUsersToAssignToDirectionQuery, GetManageUsersToAssignToDirectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManageUsersToAssignToDirectionQuery, GetManageUsersToAssignToDirectionQueryVariables>(
    GetManageUsersToAssignToDirectionDocument,
    options
  );
}
export type GetManageUsersToAssignToDirectionQueryHookResult = ReturnType<typeof useGetManageUsersToAssignToDirectionQuery>;
export type GetManageUsersToAssignToDirectionLazyQueryHookResult = ReturnType<typeof useGetManageUsersToAssignToDirectionLazyQuery>;
export type GetManageUsersToAssignToDirectionQueryResult = Apollo.QueryResult<
  GetManageUsersToAssignToDirectionQuery,
  GetManageUsersToAssignToDirectionQueryVariables
>;
export const GetManageGroupsToAssignToDirectionDocument = gql`
  query getManageGroupsToAssignToDirection($companyId: Int) {
    getManageGroupsToAssignToDirection(companyId: $companyId) {
      id
      name
    }
  }
`;

/**
 * __useGetManageGroupsToAssignToDirectionQuery__
 *
 * To run a query within a React component, call `useGetManageGroupsToAssignToDirectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageGroupsToAssignToDirectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageGroupsToAssignToDirectionQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetManageGroupsToAssignToDirectionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManageGroupsToAssignToDirectionQuery, GetManageGroupsToAssignToDirectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManageGroupsToAssignToDirectionQuery, GetManageGroupsToAssignToDirectionQueryVariables>(
    GetManageGroupsToAssignToDirectionDocument,
    options
  );
}
export function useGetManageGroupsToAssignToDirectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManageGroupsToAssignToDirectionQuery, GetManageGroupsToAssignToDirectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManageGroupsToAssignToDirectionQuery, GetManageGroupsToAssignToDirectionQueryVariables>(
    GetManageGroupsToAssignToDirectionDocument,
    options
  );
}
export type GetManageGroupsToAssignToDirectionQueryHookResult = ReturnType<typeof useGetManageGroupsToAssignToDirectionQuery>;
export type GetManageGroupsToAssignToDirectionLazyQueryHookResult = ReturnType<typeof useGetManageGroupsToAssignToDirectionLazyQuery>;
export type GetManageGroupsToAssignToDirectionQueryResult = Apollo.QueryResult<
  GetManageGroupsToAssignToDirectionQuery,
  GetManageGroupsToAssignToDirectionQueryVariables
>;
export const ManageCallbackHistoryDocument = gql`
  query manageCallbackHistory($input: ManageCallbackHistoryParamsInput!) {
    manageCallbackHistory(input: $input) {
      total
      items {
        id
        createdAt
        eventType
        body
        responseStatus
        responseBody
        error
      }
    }
  }
`;

/**
 * __useManageCallbackHistoryQuery__
 *
 * To run a query within a React component, call `useManageCallbackHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCallbackHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCallbackHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageCallbackHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<ManageCallbackHistoryQuery, ManageCallbackHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageCallbackHistoryQuery, ManageCallbackHistoryQueryVariables>(ManageCallbackHistoryDocument, options);
}
export function useManageCallbackHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageCallbackHistoryQuery, ManageCallbackHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageCallbackHistoryQuery, ManageCallbackHistoryQueryVariables>(ManageCallbackHistoryDocument, options);
}
export type ManageCallbackHistoryQueryHookResult = ReturnType<typeof useManageCallbackHistoryQuery>;
export type ManageCallbackHistoryLazyQueryHookResult = ReturnType<typeof useManageCallbackHistoryLazyQuery>;
export type ManageCallbackHistoryQueryResult = Apollo.QueryResult<ManageCallbackHistoryQuery, ManageCallbackHistoryQueryVariables>;
export const ManageCallbackDocument = gql`
  query manageCallback {
    manageCallback {
      url
      key
      enabled
    }
  }
`;

/**
 * __useManageCallbackQuery__
 *
 * To run a query within a React component, call `useManageCallbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCallbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCallbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageCallbackQuery(baseOptions?: Apollo.QueryHookOptions<ManageCallbackQuery, ManageCallbackQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageCallbackQuery, ManageCallbackQueryVariables>(ManageCallbackDocument, options);
}
export function useManageCallbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageCallbackQuery, ManageCallbackQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageCallbackQuery, ManageCallbackQueryVariables>(ManageCallbackDocument, options);
}
export type ManageCallbackQueryHookResult = ReturnType<typeof useManageCallbackQuery>;
export type ManageCallbackLazyQueryHookResult = ReturnType<typeof useManageCallbackLazyQuery>;
export type ManageCallbackQueryResult = Apollo.QueryResult<ManageCallbackQuery, ManageCallbackQueryVariables>;
export const ManageCompaniesGroupsListDocument = gql`
  query manageCompaniesGroupsList($input: ManageCompaniesGroupsListInput!) {
    manageCompaniesGroupsList(input: $input) {
      total
      items {
        id
        name
        frozenAt
        frozenReason
        users
        companies
        clients
        techAdminName
        techAdminEmail
        techAdminPhone
      }
    }
  }
`;

/**
 * __useManageCompaniesGroupsListQuery__
 *
 * To run a query within a React component, call `useManageCompaniesGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCompaniesGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCompaniesGroupsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageCompaniesGroupsListQuery(
  baseOptions: Apollo.QueryHookOptions<ManageCompaniesGroupsListQuery, ManageCompaniesGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageCompaniesGroupsListQuery, ManageCompaniesGroupsListQueryVariables>(
    ManageCompaniesGroupsListDocument,
    options
  );
}
export function useManageCompaniesGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageCompaniesGroupsListQuery, ManageCompaniesGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageCompaniesGroupsListQuery, ManageCompaniesGroupsListQueryVariables>(
    ManageCompaniesGroupsListDocument,
    options
  );
}
export type ManageCompaniesGroupsListQueryHookResult = ReturnType<typeof useManageCompaniesGroupsListQuery>;
export type ManageCompaniesGroupsListLazyQueryHookResult = ReturnType<typeof useManageCompaniesGroupsListLazyQuery>;
export type ManageCompaniesGroupsListQueryResult = Apollo.QueryResult<
  ManageCompaniesGroupsListQuery,
  ManageCompaniesGroupsListQueryVariables
>;
export const ManageCompanyCarsDocument = gql`
  query manageCompanyCars($input: ManageCarsListInput!) {
    manageGetCars(input: $input) {
      total
      items {
        id
        brand
        model
        companyId
        carComplectationId
        carComplectation {
          id
          name
          modification {
            id
            name
            yearFrom
            yearTo
            fuelType
            driveType
            transmission
            power
            engineSize
            bodyType
            doors
            generation {
              name
              model {
                name
                make {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useManageCompanyCarsQuery__
 *
 * To run a query within a React component, call `useManageCompanyCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageCompanyCarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageCompanyCarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageCompanyCarsQuery(baseOptions: Apollo.QueryHookOptions<ManageCompanyCarsQuery, ManageCompanyCarsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageCompanyCarsQuery, ManageCompanyCarsQueryVariables>(ManageCompanyCarsDocument, options);
}
export function useManageCompanyCarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageCompanyCarsQuery, ManageCompanyCarsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageCompanyCarsQuery, ManageCompanyCarsQueryVariables>(ManageCompanyCarsDocument, options);
}
export type ManageCompanyCarsQueryHookResult = ReturnType<typeof useManageCompanyCarsQuery>;
export type ManageCompanyCarsLazyQueryHookResult = ReturnType<typeof useManageCompanyCarsLazyQuery>;
export type ManageCompanyCarsQueryResult = Apollo.QueryResult<ManageCompanyCarsQuery, ManageCompanyCarsQueryVariables>;
export const ManageGetCarsLibraryDocument = gql`
  query manageGetCarsLibrary($input: ListCarsLibraryInput!) {
    manageGetCarsLibrary(input: $input) {
      total
      items {
        id
        name
        modification {
          id
          name
          yearFrom
          yearTo
          fuelType
          driveType
          transmission
          power
          engineSize
          bodyType
          doors
          generation {
            name
            model {
              name
              make {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useManageGetCarsLibraryQuery__
 *
 * To run a query within a React component, call `useManageGetCarsLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageGetCarsLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageGetCarsLibraryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageGetCarsLibraryQuery(
  baseOptions: Apollo.QueryHookOptions<ManageGetCarsLibraryQuery, ManageGetCarsLibraryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageGetCarsLibraryQuery, ManageGetCarsLibraryQueryVariables>(ManageGetCarsLibraryDocument, options);
}
export function useManageGetCarsLibraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageGetCarsLibraryQuery, ManageGetCarsLibraryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageGetCarsLibraryQuery, ManageGetCarsLibraryQueryVariables>(ManageGetCarsLibraryDocument, options);
}
export type ManageGetCarsLibraryQueryHookResult = ReturnType<typeof useManageGetCarsLibraryQuery>;
export type ManageGetCarsLibraryLazyQueryHookResult = ReturnType<typeof useManageGetCarsLibraryLazyQuery>;
export type ManageGetCarsLibraryQueryResult = Apollo.QueryResult<ManageGetCarsLibraryQuery, ManageGetCarsLibraryQueryVariables>;
export const ManageClientsDownloadLinkDocument = gql`
  query manageClientsDownloadLink {
    manageClientsDownloadLink
  }
`;

/**
 * __useManageClientsDownloadLinkQuery__
 *
 * To run a query within a React component, call `useManageClientsDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageClientsDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageClientsDownloadLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageClientsDownloadLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<ManageClientsDownloadLinkQuery, ManageClientsDownloadLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageClientsDownloadLinkQuery, ManageClientsDownloadLinkQueryVariables>(
    ManageClientsDownloadLinkDocument,
    options
  );
}
export function useManageClientsDownloadLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageClientsDownloadLinkQuery, ManageClientsDownloadLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageClientsDownloadLinkQuery, ManageClientsDownloadLinkQueryVariables>(
    ManageClientsDownloadLinkDocument,
    options
  );
}
export type ManageClientsDownloadLinkQueryHookResult = ReturnType<typeof useManageClientsDownloadLinkQuery>;
export type ManageClientsDownloadLinkLazyQueryHookResult = ReturnType<typeof useManageClientsDownloadLinkLazyQuery>;
export type ManageClientsDownloadLinkQueryResult = Apollo.QueryResult<
  ManageClientsDownloadLinkQuery,
  ManageClientsDownloadLinkQueryVariables
>;
